import React, { useEffect, useState, useCallback } from "react";
import "react-vis/dist/style.css";
import {
  Breadcrumb,
  Row,
  Col,
  Tabs,
  Table,
  Collapse,
  Pagination,
  Tooltip,
} from "antd";
import axios from "axios";
import { CaretRightOutlined } from "@ant-design/icons";

import WeightDispute from "../Transactions/WeightDispute";
import { formatDateTime } from "../../helpers/date";
import ServiceProviderTag from "../shared/ServiceProviderTag";
import ReferralAccountFilter from "./ReferralAccountFilter";
// import AccountManagerFilter from "./AccountManagerFilter";
import { TransactionButtons } from "../Transactions/TransactionButtons.js";

import { PERMISSIONS } from "../../constants/roles";

const routes = [
  { path: "", breadcrumbName: "App" },
  { path: "/transaction", breadcrumbName: "Transaction" },
];

const table = [
  {
    title: "ID",
    dataIndex: "objectId",
  },
  {
    title: "Merchant",
    dataIndex: "merchant",
    key: "username",
    render: (text) => (
      <Tooltip title={text?.user?.email || "-"}>
        {text?.user?.username || "-"}{" "}
      </Tooltip>
    ),
  },
  {
    title: "Amount",
    dataIndex: "amount",
  },
  {
    title: "Method",
    dataIndex: "method",
  },
  {
    title: "Success At (YYYY-MM-DD)",
    dataIndex: "creditSuccessAt",
    render: (text) => (text ? formatDateTime(text) : "-"),
  },
  {
    title: "Remarks",
    dataIndex: "remarks",
    render: (text) => text || "-",
  },
];

const Commission = ({ permission }) => {
  const [transactions, setTransactions] = useState([]);
  const [filters, setFilters] = useState({});
  const [pagination, setPagination] = useState({ currentPage: 1, limit: 10 });
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  const fetchTransactions = useCallback(async () => {
    setIsLoading(true);
    const params = {
      types: ["Referee Bonus", "Referral Bonus", "Top up Commission"],
      ...(filters.startDate !== null && {
        startDate: filters.startDate?.startOf("month")?.toISOString(),
      }),
      ...(filters.endDate !== null && {
        endDate: filters.endDate?.endOf("month")?.toISOString(),
      }),
      ...(filters.search && {
        searchField: filters.searchField,
        search: filters.search,
      }),
      limit: pagination.limit,
      page: pagination.currentPage,
    };

    const res = await axios.get(`/api/transactions`, {
      params,
    });

    setTransactions(res.data.data);
    setPagination(res.data.pagination);
    setIsLoading(false);
  }, [pagination.currentPage, pagination.limit, filters]);

  useEffect(() => {
    fetchTransactions();
  }, [pagination.currentPage, pagination.limit, fetchTransactions]);

  return (
    <div>
      <Collapse
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
      >
        <Collapse.Panel header="Filters" key="filters">
          <ReferralAccountFilter
            filters={filters}
            onFiltersChange={(filters) => setFilters(filters)}
            tabName="commission"
            defaultField="userEmail"
          />
        </Collapse.Panel>
      </Collapse>

      <div className="table mt-4">
        <Row
          className="mt-2 mb-2"
          justify={
            permission?.includes(PERMISSIONS.EXPORT_LIST)
              ? "space-between"
              : "end"
          }
          align="middle"
        >
          {permission?.includes(PERMISSIONS.EXPORT_LIST) && (
            <TransactionButtons
              filters={{
                types: ["Referee Bonus", "Referral Bonus", "Top up Commission"],
                ...(filters.startDate !== null && {
                  startDate: filters.startDate?.startOf("day")?.toISOString(),
                }),
                ...(filters.endDate !== null && {
                  endDate: filters.endDate?.endOf("day")?.toISOString(),
                }),
                ...(filters.search && {
                  searchField: filters.searchField,
                  search: filters.search,
                }),
                pagination: pagination,
              }}
            />
          )}

          <Pagination
            total={pagination?.totalSize}
            pageSize={pagination?.limit}
            onChange={(currentPage, limit) => {
              setPagination({ ...pagination, limit, currentPage });
            }}
            current={pagination.currentPage}
          />
        </Row>

        <Table
          className="table-section"
          columns={table}
          dataSource={transactions}
          pagination={false}
          scroll={{ x: true }}
          loading={isLoading}
        />

        <Row justify="end">
          <Col>
            <Pagination
              total={pagination?.totalSize}
              pageSize={pagination?.limit}
              onChange={(currentPage, limit) => {
                setPagination({ ...pagination, limit, currentPage });
              }}
              current={pagination.currentPage}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Commission;
