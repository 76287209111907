import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";

const PermissionsContext = createContext();

export const PermissionsProvider = ({ children, token }) => {
  const [permissions, setPermissions] = useState([]);

  useEffect(() => {
    const getPermissions = async () => {
      if (!token) return; // Early return if token is not available

      try {
        const res = await axios.get("/api/users/permission", {
          headers: { Authorization: `Bearer ${token}` },
        });
        setPermissions(res.data.data);
      } catch (error) {
        console.error("Error fetching permissions:", error);
      }
    };

    getPermissions();
  }, [token]);

  return (
    <PermissionsContext.Provider value={{ permissions }}>
      {children}
    </PermissionsContext.Provider>
  );
};

export const usePermissions = () => {
  return useContext(PermissionsContext);
};
