import React, { useState, useEffect, useCallback } from 'react'
import { Pagination, Row, Col, Collapse } from 'antd'
import axios from 'axios'
import moment from 'moment'
import { CaretRightOutlined } from '@ant-design/icons'

import PageHeader from '../Layout/PageHeader'
import OrdersList from './OrdersList'
import OrderButtons from './OrderButtons'
import NextDayRemittanceOrderFilters from './NextDayRemittanceOrderFilters'
import PayoutForm from '../Remittance/PayoutForm'

import { PERMISSIONS } from "../../constants/roles";
import { usePermissions } from "../App/PermissionContext"; 

const today = moment()
const oneWeekAgo = moment().subtract(7, 'days')

const NextDayRemittanceOrder = () => {
  const { permissions } = usePermissions();
  const [orders, setOrders] = useState([])
  const [filters, setFilters] = useState({ isRemittance: null, startDate: oneWeekAgo, endDate: today })
  const [pagination, setPagination] = useState({ currentPage: 1, limit: 10 })
  const [isLoading, setIsLoading] = useState(false)
  const [selectedRows, setSelectedRows] = useState([])

  const fetchOrders = useCallback(async () => {
    setIsLoading(true)

    const res = await axios.get("/api/orders", { 
      params: {
        isNextDayRemittance: true,
        ...(filters.startDate !== null) && { startDate: filters.startDate?.startOf('day')?.toISOString() },
        ...(filters.endDate !== null) && { endDate: filters.endDate?.endOf('day')?.toISOString() },
        ...(filters.isRemittance !== null) && { isRemittance: filters.isRemittance },
        limit: pagination.limit,
        page: pagination.currentPage
      } 
    })

    setOrders(res.data.orders)
    setPagination(res.data.pagination)
    setIsLoading(false)
  }, [pagination.currentPage, pagination.limit, filters])

  useEffect(() => {
    fetchOrders()
  }, [pagination.currentPage, pagination.limit, fetchOrders])

  return (
    <div>
      <PageHeader 
        title="Next-day Remittance Order"
        childRoutes={[{ path: "/next-day-remittance-order", breadcrumbName: "Next-day Remittance Order" }]}
      />

      <Collapse expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}>
        <Collapse.Panel header="Filters" key="filters">
          <NextDayRemittanceOrderFilters
            filters={filters} 
            onFiltersChange={filters => setFilters(filters)} 
          />
        </Collapse.Panel>
      </Collapse>
      
      {permissions?.includes(PERMISSIONS.PAYOUT_UPLOAD) && (
        <Collapse
          expandIcon={({ isActive }) => (
            <CaretRightOutlined rotate={isActive ? 90 : 0} />
          )}
        >
          <Collapse.Panel header="New Payout" key="payout-panel">
            <PayoutForm />
          </Collapse.Panel>
        </Collapse>
      )}
      <Row
          className="mt-2 mb-2"
          justify="space-between"
          align="middle"
        >
        <OrderButtons
            
            selectedRowKeys={selectedRows}
            onRefunded={() => fetchOrders()}
            orders={orders}
            filters={{
              isNextDayRemittance: true,
              ...(filters.startDate !== null && {
                startDate: filters.startDate?.startOf("day")?.toISOString(),
              }),
              ...(filters.endDate !== null && {
                endDate: filters.endDate?.endOf("day")?.toISOString(),
              }),
              ...(filters.isRemittance !== null && {
                isRemittance: filters.isRemittance,
              }),
              pagination,
            }}
          />

        <Col>
          <Pagination 
            total={pagination?.totalSize} 
            pageSize={pagination?.limit}
            onChange={((currentPage, limit) => { setPagination({ ...pagination, limit, currentPage }) })}
            current={pagination.currentPage} 
          />
        </Col>
      </Row>

      <OrdersList 
        orders={orders} 
        onSelectRows={selectedRowKeys => setSelectedRows(selectedRowKeys)}
        selectedRows={selectedRows}
        loading={isLoading}
        
      />

      <Row justify="end">
        <Col>
          <Pagination 
            total={pagination?.totalSize} 
            pageSize={pagination?.limit}
            onChange={((currentPage, limit) => { setPagination({ ...pagination, limit, currentPage }) })}
            current={pagination.currentPage} 
          />
        </Col>
      </Row>
    </div>
  )
}

export default NextDayRemittanceOrder