import React, { useState } from "react"
import { Select, Space, Input, DatePicker } from "antd"

const PayoutFilters = ({filters, onFiltersChange}) => {
  const [searchField, setSearchField] = useState("remark")
  const searchBy = (
    <Select defaultValue="remark" className="select-before" onChange={value => setSearchField(value)}>
      <Select.Option value="remark">Remark</Select.Option>
      <Select.Option value="userEmail">User Email</Select.Option>
      <Select.Option value="transactionNo">Transaction No</Select.Option>
      <Select.Option value="id">Payout ID</Select.Option>
      <Select.Option value="trackingNo">Tracking Number</Select.Option>
    </Select>
  );

  return (
    <Space size="large">
      <div>
        <DatePicker.RangePicker
          value={[filters.startDate, filters.endDate]}
          onChange={dates => (dates 
              ? onFiltersChange({...filters, startDate: dates[0], endDate: dates[1]}) 
              : onFiltersChange({...filters, startDate: undefined, endDate: undefined})
          )}
        />
      </div>

      <div>
        <Input.Search 
          addonBefore={searchBy}
          placeholder="Search" 
          onSearch={term => onFiltersChange({...filters, search: term, searchField }) } 
        />
      </div>

      <div>
        Status :{` `}
        <Select 
          placeholder="ALL"
          style={{ width: 120 }}
          allowClear 
          onChange={value => onFiltersChange({...filters, isSuccess: value})}
        >
          <Select.Option value="true">Success</Select.Option>
          <Select.Option value="false">Failed</Select.Option>
        </Select>
      </div>
    </Space>
  )
}

export default PayoutFilters;
