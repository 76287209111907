import React, { useEffect, useState, useCallback } from "react";
import "react-vis/dist/style.css";
import {
  Breadcrumb,
  Row,
  Col,
  Tabs,
  Table,
  Collapse,
  Pagination,
  Tooltip,
} from "antd";
import axios from "axios";
import { CaretRightOutlined } from "@ant-design/icons";

import WeightDispute from "../Transactions/WeightDispute";
import { formatDateTime } from "../../helpers/date";
import ServiceProviderTag from "../shared/ServiceProviderTag";
import TransactionFilters from "../Transactions/TransactionFilters";
import { TransactionButtons } from "../Transactions/TransactionButtons.js";
import { PERMISSIONS } from "../../constants/roles";
import { usePermissions } from "../App/PermissionContext"; 

const routes = [
  { path: "", breadcrumbName: "App" },
  { path: "/transaction", breadcrumbName: "Transaction" },
];

const table = [
  {
    title: "ID",
    dataIndex: "objectId",
  },
  {
    title: "Merchant",
    dataIndex: "merchant",
    key: "username",
    render: (text) => (
      <Tooltip title={text?.user?.email || "-"}>
        {text?.user?.username || "-"}{" "}
      </Tooltip>
    ),
  },
  {
    title: "Order ID",
    dataIndex: "order",
    key: "orderId",
    render: (text, row) => text?.objectId || row.ref_no || "-",
  },
  {
    title: "Tracking No",
    dataIndex: "order",
    key: "trackingNo",
    render: (text) => text?.consign_no || "-",
  },
  {
    title: "Courier",
    dataIndex: "order",
    render: (text, row) =>
      text?.serviceProvider ? (
        <ServiceProviderTag serviceProviderKey={text.serviceProvider} />
      ) : (
        ""
      ),
  },
  {
    title: "Amount",
    dataIndex: "amount",
  },
  {
    title: "Method",
    dataIndex: "method",
  },
  {
    title: "Success At (YYYY-MM-DD)",
    dataIndex: "creditSuccessAt",
    render: (text) => (text ? formatDateTime(text) : "-"),
  },
  {
    title: "Remarks",
    dataIndex: "remarks",
    render: (text) => text || "-",
  },
];

const tableAllTransaction = [
  {
    title: "ID",
    dataIndex: "objectId",
  },
  {
    title: "Merchant",
    dataIndex: "merchant",
    key: "username",
    render: (text) => (
      <Tooltip title={text?.user?.email || "-"}>
        {text?.user?.username || "-"}{" "}
      </Tooltip>
    ),
  },
  {
    title: "Order ID",
    dataIndex: "order",
    key: "orderId",
    render: (text, row) => text?.objectId || row.ref_no || "-",
  },
  {
    title: "Tracking No",
    dataIndex: "order",
    key: "trackingNo",
    render: (text) => text?.consign_no || "-",
  },
  {
    title: "Courier",
    dataIndex: "order",
    render: (text, row) =>
      text?.serviceProvider ? (
        <ServiceProviderTag serviceProviderKey={text.serviceProvider} />
      ) : (
        ""
      ),
  },
  {
    title: "Amount",
    dataIndex: "amount",
  },
  {
    title: "Credit Balance",
    dataIndex: "credit",
    key: "bal_new",
    render: (text, row) => text?.credit || "-",
  },
  {
    title: "Method",
    dataIndex: "method",
  },
  {
    title: "Success At (YYYY-MM-DD)",
    dataIndex: "creditSuccessAt",
    render: (text) => (text ? formatDateTime(text) : "-"),
  },
  {
    title: "Remarks",
    dataIndex: "remarks",
    render: (text) => text || "-",
  },
  {
    title: "Reference",
    dataIndex: "reference",
    render: (text) => text || "-",
  },
];

const UserTransaction = ({ merchantId, method, loading }) => {
  const [transactions, setTransactions] = useState([]);
  const [filters, setFilters] = useState({});
  const [pagination, setPagination] = useState({ currentPage: 1, limit: 10 });
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  const { permissions } = usePermissions();

  console.log(permissions)

  const fetchTransactions = useCallback(async () => {
    setIsLoading(true);
    const params = {
      merchantId,
      ...(filters.startDate !== null && {
        startDate: filters.startDate?.startOf("day")?.toISOString(),
      }),
      ...(filters.endDate !== null && {
        endDate: filters.endDate?.endOf("day")?.toISOString(),
      }),
      ...(filters.search && {
        searchField: filters.searchField,
        search: filters.search,
      }),
      limit: pagination.limit,
      page: pagination.currentPage,
    };

    const res = await axios.get(`/api/transactions?types=${method}`, {
      params,
    });

    setTransactions(res.data.data);
    setPagination(res.data.pagination);
    setIsLoading(false);
  }, [pagination.currentPage, pagination.limit, filters]);

  useEffect(() => {
    fetchTransactions();
  }, [pagination.currentPage, pagination.limit, fetchTransactions]);

  return (
    <div>
      <Collapse
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
      >
        <Collapse.Panel header="Filters" key="filters">
          <TransactionFilters
            filters={filters}
            onFiltersChange={(filters) => setFilters(filters)}
          />
        </Collapse.Panel>
      </Collapse>

      <div className="table mt-4">
        <Row
          className="mt-2 mb-2"
          justify={
            permissions?.includes(PERMISSIONS.EXPORT_LIST)
              ? "space-between"
              : "end"
          }
          align="middle"
        >
          {permissions?.includes(PERMISSIONS.EXPORT_LIST) && (
            <TransactionButtons
              filters={{
                ...(filters.startDate !== null && {
                  startDate: filters.startDate?.startOf("day")?.toISOString(),
                }),
                ...(filters.endDate !== null && {
                  endDate: filters.endDate?.endOf("day")?.toISOString(),
                }),
                ...(filters.search && {
                  searchField: filters.searchField,
                  search: filters.search,
                }),
                pagination: pagination,
                merchantId,
              }}
            />
          )}

          <Col>
            <Pagination
              total={pagination?.totalSize}
              pageSize={pagination?.limit}
              onChange={(currentPage, limit) => {
                setPagination({ ...pagination, limit, currentPage });
              }}
              current={pagination.currentPage}
            />
          </Col>
        </Row>
        {method === "" ? (
          <Table
            className="table-section"
            columns={tableAllTransaction}
            dataSource={transactions}
            pagination={false}
            scroll={{ x: true }}
            loading={loading}
          />
        ) : (
          <Table
            className="table-section"
            columns={table}
            dataSource={transactions}
            pagination={false}
            scroll={{ x: true }}
            loading={loading}
          />
        )}

        <Row justify="end">
          <Col>
            <Pagination
              total={pagination?.totalSize}
              pageSize={pagination?.limit}
              onChange={(currentPage, limit) => {
                setPagination({ ...pagination, limit, currentPage });
              }}
              current={pagination.currentPage}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default UserTransaction;
