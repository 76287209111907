import React, { useEffect, useState } from "react"
import axios from "axios"
import { Breadcrumb, Row, Col, message, Pagination, Collapse, Button, Form, Modal,Input, InputNumber, Select, Space } from "antd"
import { CaretRightOutlined, DownloadOutlined, InfoCircleOutlined,FormOutlined } from '@ant-design/icons'

import FlyersRequestList from "./FlyersRequestList"
import FlyersRequestFilters from "./FlyersRequestFilters"
import DragUpload from "../Form/DragUpload";

import { PERMISSIONS } from "../../constants/roles";
import { usePermissions } from "../App/PermissionContext"; 

const routes = [
  { path: "", breadcrumbName: "App" },
  { path: "/flyers-request", breadcrumbName: "Flyers Request" }
];

const SIZES = ["A2","A3","A4"]

const FlyersRequest = () => {
  const { permissions } = usePermissions();
  
  const [loading, setLoading] = useState({ index: false, export: false })
  const [flyerRequests, setFlyerRequests] = useState()
  const [pagination, setPagination] = useState({ currentPage: 1, limit: 10 })
  const [filters, setFilters] = useState({})
  const [bulkResults, setBulkResults] = useState();
  const [merchant, setMerchant] = useState();
  const [addresses, setAddresses] = useState([]);
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = async() => {
    setVisible(false);
    const val = form.getFieldValue();
    const sizes = Object.entries(val)
    .filter(([key, quantity]) => SIZES.includes(key) && quantity > 0)
      .map(([size, quantity]) => ({
        size,
        quantity,
      }));
    
    if(form.getFieldValue("address")!==undefined && sizes.some((size) => size.quantity > 0 )){
      await axios.post("/api/flyer-requests", {
        sizes,
        address: addresses[form.getFieldValue("address")],
        merchantId: merchant,
      }).then(() =>{
        message.success("Flyers Request created successfully")    
      });
      form.resetFields()
    }
    else {
      message.error('At least one size of the flyers is not zero and an address is choosen');
    }
    form.resetFields()
    setAddresses([])
    setMerchant("")
  };

  const handleCancel = () => {
    setVisible(false);
    form.resetFields()
    setAddresses([])
    setMerchant("")
  };

  const searchMerchant = async (value) => {
    const merchantResponse = await axios.get(`/api/users`, {
      params: {
        searchField: "email",
        search: value,
      },
    });
    if(merchantResponse.data.data.length>0){
      setMerchant(merchantResponse.data.data[0].merchantId);

      const addressResponse = await axios.get("/api/address", {
        params: {
          merchantId: merchantResponse.data.data[0].merchantId,
        },
      });

      if(addressResponse.data.address.length>0){
        setAddresses(addressResponse.data.address);
      }
      else {
        message.error('This merchant has no sender address');
      }

    }
    else {
      message.error('No merchant found by the ' + value );
    }
    
  };

  useEffect(() => {
    const fetchFlyerRequests = async () => {
      setLoading(prevLoading => ({ ...prevLoading, index: true }))

      const res = await axios.get(`/api/flyer-requests`
      , {
        params: {
          limit: pagination.limit,
          page: pagination.currentPage,
          sort: filters.sort,
          order: filters.order,
          status: filters.status,
          startDate: filters.startDate?.toISOString(),
          endDate: filters.endDate?.toISOString(),
          ...(filters.search && { searchField: filters.searchField, search: filters.search }),
        }
      })
      setFlyerRequests(res.data.data)
      setPagination(res.data.pagination)
      setLoading(prevLoading => ({ ...prevLoading, index: false }))
    }

    fetchFlyerRequests()
  }, [pagination.currentPage, pagination.limit, filters])

  const updateHandler = async (flyerRequestId, params) => {
    try {
      await axios.patch(`/api/flyer-requests/${flyerRequestId}`, params)
      message.success(`Flyer Request (${flyerRequestId}) updated.`)
    } catch (error) {
      message.error(error.response.data.message || error.message)
    }
  }

  const archiveHandler = async flyerRequestId => {
    try {
      await axios.patch(`/api/flyer-requests/${flyerRequestId}/archive`)
      message.info(`Flyer Request (${flyerRequestId}) has been archived.`)
    } catch (error) {
      message.error(error.response.data.message || error.message)
    }
  }

  const tableChangeHandler = (...[, , sorter]) => {
    sorter.order && setFilters({ ...filters, sort: sorter.field, order: sorter.order })
  }

  const exportDownload = async () => {
    setLoading(prevLoading => ({ ...prevLoading, export: true }))

    const params = {
      status: filters.status,
      startDate: filters.startDate?.toISOString(),
      endDate: filters.endDate?.toISOString(),
      ...(filters.search && { searchField: filters.searchField, search: filters.search }),
    }

    const messageContent = (
      <p>
        Are you sure you want to download {pagination.totalSize} orders data ?<br/>
      </p>
    );

    Modal.confirm({
      content: messageContent,
      title: `Download Flyers Request List ? `,
      async onOk() {
        await axios
        .get("api/flyer-requests/export", { params })
          .then((res) => {
            message.info(`Please check your email after 5-20 minute`);
          })
          .catch((error) => {
            message.error(error.message);
          });
      },
    });

    setLoading(prevLoading => ({ ...prevLoading, export: false }))
  }

  const uploadSuccessHandler = (res) => {
    setBulkResults(res.data.data);
  };

  return (
    <div>
      <div className="header">
        <Row>
          <Col span={12}>
            <h2 className="detail-header-text">Flyers Request</h2>
          </Col>
          <Col span={12}>
            <Breadcrumb
            style={{ margin: "10px 0", textAlign: "right" }}
            routes={routes}
          />
          </Col>
        </Row>
      </div>
      {permissions?.includes(PERMISSIONS.CRUD_FLYERS_REQUEST) && (
        <Collapse
          expandIcon={({ isActive }) => (
            <CaretRightOutlined rotate={isActive ? 90 : 0} />
          )}
        >
          <Collapse.Panel header="Bulk Update" key="bulk-update">
            {bulkResults && (
              <Collapse
                className="mt-2"
                size="small"
                expandIcon={({ isActive }) => (
                  <CaretRightOutlined rotate={isActive ? 90 : 0} />
                )}
              >
                <Collapse.Panel
                  header={
                    <span>
                      <InfoCircleOutlined /> Results of Last Bulk
                    </span>
                  }
                  key="last-bulk-result"
                >
                  <h4>
                    <strong>Updated</strong>
                  </h4>
                  <ul>
                    <li>
                      {bulkResults
                        .filter((result) => result.success)
                        .map((result) => result.id)
                        .join(", ")}
                    </li>
                  </ul>
                  <h4>
                    <strong>Failed</strong>
                  </h4>
                  <ol>
                    {bulkResults
                      .filter((result) => !result.success)
                      .map((fail) => (
                        <li key={fail.id}>
                          {fail.id}: {fail.message}
                        </li>
                      ))}
                  </ol>
                </Collapse.Panel>
              </Collapse>
            )}

            <p class="mt-2">
              Notes:
              <ul>
                <li>Use the same format/file with the exported Excel.</li>
                <li>
                  Only <b>Status</b> and <b>Tracking ID</b> columns will be
                  updated.
                </li>
                <ul>
                  <li>
                    - <b>Fulfilled At</b> will be auto updated to current date
                    when Status changed <b>from others to POSTED</b>.
                  </li>
                  <li>
                    - <b>Fulfilled At & Tracking ID</b> will be auto updated to
                    none when Status is other than <b>POSTED</b>.
                  </li>
                </ul>
              </ul>
            </p>

            <DragUpload
              action={`${process.env.REACT_APP_SERVER_URL}/api/flyer-requests/bulk-update`}
              accept=".xls, .xlsx"
              method="post"
              onUploadSuccess={(res) => uploadSuccessHandler(res)}
            />
          </Collapse.Panel>
        </Collapse>
      )}
    

      <Collapse
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
      >
        <Collapse.Panel header="Filters" key="filters">
          <FlyersRequestFilters
            filters={filters}
            onFiltersChange={(filters) => setFilters(filters)}
          />
        </Collapse.Panel>
      </Collapse>
      <Row className="mt-2 mb-2" justify="space-between">
      <Space>
          {permissions?.includes(PERMISSIONS.EXPORT_LIST) && (
            <span color="blue">
              <Button onClick={exportDownload} loading={loading.export}>
                Export <DownloadOutlined></DownloadOutlined>
              </Button>
            </span>
          )}

          <span></span>

          {permissions?.includes(PERMISSIONS.CRUD_FLYERS_REQUEST) && (
            <span color="blue">
              <Button onClick={showModal}>
                Add Flyers Request <FormOutlined></FormOutlined>
              </Button>
            </span>
          )}
        </Space>

        <Col>
          <Pagination 
            total={pagination?.totalSize} 
            pageSize={pagination?.limit}
            onChange={((currentPage, limit) => { setPagination({ ...pagination, limit, currentPage }) })}
            current={pagination.currentPage} 
          />
        </Col>
      </Row>

      {flyerRequests && <FlyersRequestList 
        flyerRequests={flyerRequests} 
        onUpdate={updateHandler} 
        onArchive={archiveHandler}
        onChange={tableChangeHandler}
        loading={loading.index}
        
      />}

      <Row justify="end">
        <Col>
          <Pagination 
            total={pagination?.totalSize} 
            pageSize={pagination?.limit}
            onChange={((currentPage, limit) => { setPagination({ ...pagination, limit, currentPage }) })}
            current={pagination.currentPage} 
          />
        </Col>
      </Row>
      <div>
        <Modal
          title="Basic Modal"
          visible={visible}
          onOk={handleOk}
          onCancel={handleCancel}
          width="1000px"
        >
          <Form form={form} layout="vertical">
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input the merchant email for this request",
                },
              ]}
            >
              <Input.Search onSearch={(value) => searchMerchant(value)} />
            </Form.Item>

            <Form.Item label="Select" name="address">
              <Select
                defaultValue={"Select Address"}
                className="select-before"
                width="100px"
              >
                {addresses.map((address, i) => (
                  <Select.Option key={address.objectId} value={i}>
                    {address.line1 +
                      ", " +
                      (address.line2 ? address.line2 + ", " : "") +
                      (address.line3 ? address.line3 + ", " : "") +
                      address.city +
                      ", " +
                      address.postcode +
                      ", " +
                      address.state +
                      ", " +
                      address.country}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            {SIZES.map((size) => {
              return (
                <Form.Item label={`Number of ${size} flyers`} name={size}>
                  <InputNumber step={50} min={0} defaultValue={0} />
                </Form.Item>
              );
            })}
          </Form>
        </Modal>
      </div>
     
    </div>
  )
}

export default FlyersRequest;
