import React, { useState } from 'react'
import { InputNumber, Button, Form, Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { PERMISSIONS } from "../../constants/roles";
import { usePermissions } from "../App/PermissionContext"; 

const ConversionSettings = ({ settings, onSave }) => {
  const { permissions } = usePermissions();
  
  const [saving, setSaving] = useState(false)
  const [form] = Form.useForm();

  const saveHandler = async (newSettings) => {
    setSaving(true)

    Modal.confirm({
      title: 'Confirm to update the conversion rate?',
      icon: <ExclamationCircleOutlined />,
      onOk() {
        onSave('conversion', newSettings).then(() => {
          form.resetFields()
          setSaving(false)
        })
      },
      onCancel() {
        setSaving(false)
      },
    });
    // await onSave('conversion', newSettings) 
    
    // form.resetFields()

    // setSaving(false)
  }

  return (
    <div>
      <Form
        form={form}
        name="conversion-settings"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        autoComplete="off"
        initialValues={settings}
        requiredMark={false}
        onFinish={saveHandler}
      >
        <Form.Item
          extra={`While charging COD fee from SGD, SGD 1.00 will be converted to this value in MYR`}
          label="Charge From SGD:"
          name="fromSGDCharge"
          rules={[{ required: true, message: 'Please input the rate!' }]}
        >
          <InputNumber disabled={!permissions?.includes(PERMISSIONS.UPDATE_SETTING)} step={0.001} precision={3} />
        </Form.Item>

        <Form.Item
          extra={`While remitting COD amount from SGD, SGD 1.00 will be converted to given value in MYR`}
          label="Remit From SGD:"
          name="fromSGDRemit"
          rules={[{ required: true, message: 'Please input the rate!' }]}
        >
          <InputNumber disabled={!permissions?.includes(PERMISSIONS.UPDATE_SETTING)} step={0.001} precision={3} />
        </Form.Item>

        <Form.Item
          extra={`While charging COD fee from BND, BND 1.00 will be converted to this value in MYR`}
          label="Charge From BND:"
          name="fromBNDCharge"
          rules={[{ required: true, message: 'Please input the rate!' }]}
        >
          <InputNumber disabled={!permissions?.includes(PERMISSIONS.UPDATE_SETTING)} step={0.001} precision={3} />
        </Form.Item>

        <Form.Item
          extra={`While remitting COD amount from BND, BND 1.00 will be converted to given value in MYR`}
          label="Remit From BND:"
          name="fromBNDRemit"
          rules={[{ required: true, message: 'Please input the rate!' }]}
        >
          <InputNumber disabled={!permissions?.includes(PERMISSIONS.UPDATE_SETTING)} step={0.001} precision={3} />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button disabled={!permissions?.includes(PERMISSIONS.UPDATE_SETTING)} type="primary" loading={saving} htmlType="submit">Save</Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default ConversionSettings