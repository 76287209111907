import React from 'react';
import { browserHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { Form, Input, Button, Row, Col, Card, message, Divider } from 'antd';
import { UserOutlined, KeyOutlined, UnlockOutlined } from '@ant-design/icons';
import { LOGINED } from './../../features/counter/authSlice';
import logo from './../../newLogo.png';
import axios from 'axios'
import './Login.css'

export default function Login(){

  const handleSubmit = (values, dispatch) => {
    axios.post('/api/login', {
      username: values.email,
      password: values.password
    })
    .then((response) => {
      console.log(response.data)
      dispatch(LOGINED(response.data))
      browserHistory.push('/user');
      window.location.reload();
    })
    .catch((error) => {
      console.log(error);
      message.error("Login failed");
    });
  }

  const dispatch = useDispatch();
  return (
    <div>
      <Row type="flex" justify="center" align="middle" className="wrapper">
        <Col sm={24} md={12} lg={8}>
          <Card bordered={true} hoverable={false}>
            <Row align="middle">
              <Col  className="login-section" span={14}>
                <h2>LOGIN</h2>
                <hr color="#efefef"/>
                <Form
                  name="normal_login"
                  className="login-form"
                  initialValues={{
                    remember: true,
                  }}
                  onFinish={(val) => handleSubmit(val, dispatch)}
                >
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: 'Enter your email',
                      },
                    ]}
                  >
                    <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email" />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: 'Password is required',
                      },
                    ]}
                  >
                    <Input
                      prefix={<KeyOutlined className="site-form-item-icon" />}
                      type="password"
                      placeholder="Password"
                    />
                  </Form.Item>
                  {/* <Form.Item>
                    <Form.Item name="remember" valuePropName="checked" noStyle>
                      <Checkbox>Remember me</Checkbox>
                    </Form.Item>
                  </Form.Item> */}
                  {/* <Row>
                  <Form.Item style={{float: "left", marginBottom: 10}}>
                  <a className="forgot-pwd-text" href="/forgetpassword">
                    Forgot password?
                  </a>
                  </Form.Item>
                  </Row> */}
                  <Row style={{float: "right"}}>
                    <Col span={24}>
                      <Form.Item>
                        <Button type="primary" htmlType="submit" className="login-form-button">
                          <UnlockOutlined />
                          Login
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Col>
              <Col span={10} className="logo-section">
                <img src={logo} width="120%"/>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

