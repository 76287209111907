import React, { useState, useEffect, useCallback } from 'react'
import { Pagination, Row, Col, Collapse, Button, Drawer,Modal , message } from 'antd'
import { CaretRightOutlined, InfoCircleOutlined, DownloadOutlined } from '@ant-design/icons'
import moment from 'moment'
import axios from 'axios'

import PageHeader from '../Layout/PageHeader'
import UnrecognizedStatusOrderFilters from './UnrecognizedStatusOrderFilters'
import UnrecognizedStatusOrderList from './UnrecognizedStatusOrderList'

import { PERMISSIONS } from "../../constants/roles";
import { usePermissions } from "../App/PermissionContext"; 

const today = moment()
const threeMonthsAgo = moment().subtract(3, 'months')

const UnrecognizedStatusOrder = () => {
  const { permissions } = usePermissions();
  
  const [loading, setLoading] = useState({ orders: false, export: false })
  const [unrecognizedStatusOrders, setUnrecognizedStatusOrders] = useState([])
  const [filters, setFilters] = useState({ startDate: threeMonthsAgo, endDate: today })
  const [pagination, setPagination] = useState({ currentPage: 1, limit: 10 })
  const [drawerVisible, setDrawerVisible] = useState(false);

  const fetchUnrecognizedStatusOrders = useCallback(async () => {
    setLoading(prevLoading => ({ ...prevLoading, orders: true }))

    const params = {
      ...(filters.startDate !== null) && { startDate: filters.startDate?.startOf('day')?.toISOString() },
      ...(filters.endDate !== null) && { endDate: filters.endDate?.endOf('day')?.toISOString() },
      ...(filters.serviceProvider && { serviceProvider: filters.serviceProvider }),
      limit: pagination.limit,
      page: pagination.currentPage
    }
    const res = await axios.get("/api/orders/unrecognized-status",{ params }) 
    setUnrecognizedStatusOrders(res.data.data)
    setPagination(res.data.pagination)
    setLoading(prevLoading => ({ ...prevLoading, orders: false }))
  }, [pagination.currentPage, pagination.limit, filters])

  const exportCSV = async () => {
    setLoading(prevLoading => ({ ...prevLoading, export: true }))

    const params = {
      ...(filters.startDate !== null) && { startDate: filters.startDate?.startOf('day')?.toISOString() },
      ...(filters.endDate !== null) && { endDate: filters.endDate?.endOf('day')?.toISOString() },
      ...(filters.serviceProvider && { serviceProvider: filters.serviceProvider }),
    }

    const messageContent = <p>
      Are you sure you want to download {pagination.totalSize} unrecognize status data ?<br/>
    </p>

    Modal.confirm({
      content: messageContent,
      title: `Download Unrecognized Status List ? `,
      async onOk() {
        await axios
        .post("api/orders/unrecognized-status/export", params).then(res => {
            message.info(`Please check your email after 5-20 minute`)     
          }).catch(error => {
            message.error(error.message)
          })
      },
    });

    // await axios
    //   .post("api/orders/unrecognized-status/export", params)
    //   .then(res => {
    //     const CSV = res.data.csv;
    //     window.URL = window.webkitURL || window.URL;
    //     var contentType = "text/csv";
    //     var csvFile = new Blob([CSV], { type: contentType });

    //     var a = document.createElement("a");
    //     a.download = "Unrecognized-Status-Orders.csv";
    //     a.href = window.URL.createObjectURL(csvFile);

    //     a.dataset.downloadurl = [contentType, a.download, a.href].join(":");
    //     a.click();
    //   })
    //   .catch(error => console.log(error));


    setLoading(prevLoading => ({ ...prevLoading, export: false }))
  }

  useEffect(() => {
    fetchUnrecognizedStatusOrders()
  }, [pagination.currentPage, pagination.limit, fetchUnrecognizedStatusOrders])

  return (
    <div>
      <PageHeader 
        title="Unrecognized Status"
        childRoutes={[{ path: "/unrecognized-status", breadcrumbName: "Unrecognized Status" }]}
        additionalInfo={() => (
          <React.Fragment>
            <InfoCircleOutlined onClick={() => setDrawerVisible(true)} />
            <Drawer
              title="Unrecognized Status Orders"
              placement="right"
              onClose={() => setDrawerVisible(false)}
              visible={drawerVisible}
              width={736}
            >
              <h3>What is Unrecognized Status Orders?</h3>
              <ul>
                <li>This table lists Unrecognized Status Orders for the past 3 months.</li>
                <li>Unrecognized Status Orders are orders with received tracking status that are not recognized by our mappings yet.</li>
                <li>They could be newly introduced or erroneous statuses by respective couriers.</li>
                <li>By default, for these Orders the Internal Status are set to be the same as the received tracking status.</li>
              </ul>

              <h3>How to contribute?</h3>
              <ol>
                <li>Export the list into CSV.</li>
                <li>Fill in the mappings and associated info.</li>
                <li>Submit to Tech Team.</li>
              </ol>
            </Drawer>
          </React.Fragment>
        )}
      />

      <Collapse expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}>
        <Collapse.Panel header="Filters" key="filters">
          <UnrecognizedStatusOrderFilters
            filters={filters} 
            onFiltersChange={filters => setFilters(filters)} 
          />
        </Collapse.Panel>
      </Collapse>

      <Row
        className="mt-2 mb-2"
        justify={
          permissions?.includes(PERMISSIONS.EXPORT_LIST)
            ? "space-between"
            : "start"
        }
        align="middle"
      >
        <Col>
          <Pagination
            total={pagination?.totalSize}
            pageSize={pagination?.limit}
            onChange={(currentPage, limit) => {
              setPagination({ ...pagination, limit, currentPage });
            }}
            current={pagination.currentPage}
          />
        </Col>
        {permissions?.includes(PERMISSIONS.EXPORT_LIST) && (
          <Button onClick={exportCSV} loading={loading.export}>
            <DownloadOutlined></DownloadOutlined>
            Export
          </Button>
        )}
      </Row>
      
        <UnrecognizedStatusOrderList 
          unrecognizedStatusOrders={unrecognizedStatusOrders}
          loading={loading.orders}
        />
    </div>
  )
}

export default UnrecognizedStatusOrder