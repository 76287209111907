export const PERMISSIONS = {
  DASHBOARD_PAGE: "DASHBOARD_PAGE",
  USER_PAGE: "USER_PAGE",
  VIEW_USER_DETAILS_PAGE: "VIEW_USER_DETAILS_PAGE",
  SUBACCOUNT_PAGE: "SUBACCOUNT_PAGE",
  TRANSACTION_PAGE: "TRANSACTION_PAGE",
  PACKAGE_PAGE: "PACKAGE_PAGE",
  SETTING_PAGE: "SETTING_PAGE",
  MARKETING_PAGE: "MARKETING_PAGE",
  FINANCE_PAGE: "FINANCE_PAGE",
  OPERATION_PAGE: "OPERATION_PAGE",
  FEATURES_TEST_PAGE: "FEATURES_TEST_PAGE",
  VIEW_ACCOUNT_MANAGER_PAGE: "VIEW_ACCOUNT_MANAGER_PAGE",
  QUESTIONAIRE_PAGE: "QUESTIONAIRE_PAGE",
  PAYOUT_UPLOAD: "PAYOUT_UPLOAD",
  PAYOUT_APPROVAL: "PAYOUT_APPROVAL",
  ADD_CREDIT: "ADD_CREDIT",
  EDIT_COD: "EDIT_COD",
  ASSIGN_ACCOUNT_MANAGER: "ASSIGN_ACCOUNT_MANAGER",
  MARK_CANCEL_ORDER: "MARK_CANCEL_ORDER",
  BULK_CANCEL: "BULK_CANCEL",
  BULK_REFUND: "BULK_REFUND",
  UPLOAD_PRICING: "UPLOAD_PRICING",
  BULK_REFUND_WEIGHT_DISPUTE: "BULK_REFUND_WEIGHT_DISPUTE",
  REFUND_WEIGHT_DISPUTE: "REFUND_WEIGHT_DISPUTE",
  UPDATE_CANCEL_REQUEST: "UPDATE_CANCEL_REQUEST",
  UPDATE_SETTING: "UPDATE_SETTING",
  DISCOUNT_CODE_PAGE: "DISCOUNT_CODE_PAGE",
  CRUD_FLYERS_REQUEST: "CRUD_FLYERS_REQUEST",
  UPDATE_CREDIT_TAGGING: "UPDATE_CREDIT_TAGGING",
  ASSIGN_DHL_CUSTOM_CONFIG: "ASSIGN_DHL_CUSTOM_CONFIG",
  EDIT_PHONE_EMAIL: "EDIT_PHONE_EMAIL",
  CHARGE_CARD: "CHARGE_CARD",
  ADMIN_MANUAL_TRANSACTION: "ADMIN_MANUAL_TRANSACTION",
  UPDATE_BETA_FEATURES: "UPDATE_BETA_FEATURES",
  ASSIGN_PACKAGE: "ASSIGN_PACKAGE",
  DOWNLOAD_CONNOTE: "DOWNLOAD_CONNOTE",
  REFUND_COD: "REFUND_COD",
  VIEW_CHANGE_LOG: "VIEW_CHANGE_LOG",
  SYNC_ORDER_STATUS: "SYNC_ORDER_STATUS",
  BULK_SEARCH: "BULK_SEARCH",
  BULK_DOWNLOAD_CONNOTE: "BULK_DOWNLOAD_CONNOTE",
  VIEW_PRICING: "VIEW_PRICING",
  CREATE_DISCOUNT_CODE: "CREATE_DISCOUNT_CODE",
  UPDATE_DISCOUNT_CODE: "UPDATE_DISCOUNT_CODE",
  VIEW_REFERRAL_PAGE: "VIEW_REFERRAL_PAGE",
  VIEW_ACCOUNT_MANAGER_DETAILS: "VIEW_ACCOUNT_MANAGER_DETAILS",
  UPDATE_REMITTANCE_STATUS: "UPDATE_REMITTANCE_STATUS",
  UPLOAD_CHECK_REMITTANCE: "UPLOAD_CHECK_REMITTANCE",
  BULK_UPDATE_WEIGHT_DISPUTE: "BULK_UPDATE_WEIGHT_DISPUTE",
  VIEW_PAYOUT_PAGE: "VIEW_PAYOUT_PAGE",
  VERIFY_EMAIL: "VERIFY_EMAIL",
  SYNC_BREVO: "SYNC_BREVO",
  VIEW_UNKNOWN_STATUS: "VIEW_UNKNOWN_STATUS",
  VIEW_ANALYSIS_PAGE: "VIEW_ANALYSIS_PAGE",
  UPDATE_INSURANCE_STATUS: "UPDATE_INSURANCE_STATUS",
  VIEW_INSURANCE_PAGE: "VIEW_INSURANCE_PAGE",
  UPDATE_MERCHANT: "UPDATE_MERCHANT",
  RECEIVER_ORDER_STATS: "RECEIVER_ORDER_STATS",
  VIEW_LOGS: "VIEW_LOGS",
  VIEW_WEIGHT_DISPUTE_PAGE: "VIEW_WEIGHT_DISPUTE_PAGE",
  VIEW_SYSTEM_PAGE: "VIEW_SYSTEM_PAGE",
  GENERATE_HTTP_LOGS: "GENERATE_HTTP_LOGS",
  GET_USER_PERM: "GET_USER_PERM",
  GET_INFO: "GET_INFO",
  UPDATE_PASSWORD: "UPDATE_PASSWORD",
  VIEW_REFEREE: "VIEW_REFEREE",
  CHANGE_CONNOTE_TYPE: "CHANGE_CONNOTE_TYPE",
  DASHBOARD_QUESTIONAIRE: "DASHBOARD_QUESTIONAIRE",
  UPDATE_PROFILE: "UPDATE_PROFILE",
  STATEMENT_PAGE: "STATEMENT_PAGE",
  ANNOUNCEMENT: "ANNOUNCEMENT",
  VERIFY_PHONE: "VERIFY_PHONE",
  EXPORT_LIST: "EXPORT_LIST",
  ORDER_PAGE: "ORDER_PAGE",
  VIEW_POD: "VIEW_POD",
  UPLOAD_INVOICE: "UPLOAD_INVOICE",
  DISCOUNT_CODE_CHECK: "DISCOUNT_CODE_CHECK",
  GET_RTO_STAT: "GET_RTO_STAT",
  RIDER_INFO: "RIDER_INFO",
  WOOCOMMERCE: "WOOCOMMERCE",
  SHOPIFY: "SHOPIFY",
  BULK_ORDER_EXPORT: "BULK_ORDER_EXPORT",
  CRUD_ORDER: "CRUD_ORDER",
  CRUD_SHIPMENT: "CRUD_SHIPMENT",
  REMITTANCE: "REMITTANCE",
  PRICE_QUOTE: "PRICE_QUOTE",
  CART_PAGE: "CART_PAGE",
  GENERATE_PL9: "GENERATE_PL9",
  TOPUP_PACKAGE: "TOPUP_PACKAGE",
  REQUEST_FLYERS: "REQUEST_FLYERS",
  CREDITS: "CREDITS",
  GENERATE_TOKEN: "GENERATE_TOKEN",
  ADD_WEBHOOK: "ADD_WEBHOOK",
  VERIFY_POSTCODE: "VERIFY_POSTCODE",
  CRUD_ADDRESS: "CRUD_ADDRESS",
  CRUD_CARD: "CRUD_CARD",
  SAVE_CARD:"SAVE_CARD",
  CRUD_AWB_LOGO: "UPLOAD_AWB_LOGO",
  CRUD_SUBACCOUNT: "CRUD_SUBACCOUNT",
  VIEW_INVOICE: "VIEW_INVOICE",
  GET_SETTING: "GET_SETTING",
  INSURANCE: "INSURANCE",
  DOWNLOAD_PAYMENT_ADVICE: "DOWNLOAD_PAYMENT_ADVICE",
  FLYERS_REQUEST_PAGE: "FLYERS_REQUEST_PAGE",
  REQUEST_FLYERS_PAGE: "REQUEST_FLYERS_PAGE",
  REFUND_ORDER:"REFUND_ORDER",
  UPDATE_QUESTIONAIRE_STATUS:"UPDATE_QUESTIONAIRE_STATUS",
  EDIT_QUESTIONAIRE: "EDIT_QUESTIONAIRE"
}