import React, { useState } from 'react'
import axios from 'axios'
import moment from 'moment'
import { Button, Space, notification, Dropdown, Menu, Modal, message } from 'antd';
import { UndoOutlined, DownloadOutlined, LayoutOutlined } from "@ant-design/icons"
import { PERMISSIONS } from "../../constants/roles";
import { usePermissions } from "../App/PermissionContext"; 

 export const TransactionButtons = ({filters,tabName}) => {
  const [isDownloading, setIsDownloading] = useState(false)
 
  const { permissions } = usePermissions();
  
 const downloadCSV = async () => {
  //const [pagination, setPagination] = useState({ currentPage: 1, limit: 10 })
    setIsDownloading(true)
    await axios
      .get("/api/totalCreditCount/",{ params: filters })
      .then((total)=> {
        const messageContent = <p>
          Are you sure you want to download {filters.pagination.totalSize} transactions {tabName ||filters.types} data ?<br/>
        </p>

        Modal.confirm({
          content: messageContent,
          title: `Download Transaction List ? `,
          async onOk() {
            await axios
             .get("api/transactions/export", { params: filters }).then(res => {
                message.info(`Please check your email after 5-20 minute`)     
              }).catch(error => {
                message.error(error.message)
              })
          },
        });
        setIsDownloading(false)
      })
  }


  return (
    <div style={{ marginBottom: 16, float: "right" }}>
      <Space>
      {permissions?.includes(PERMISSIONS.EXPORT_LIST) && (
          <span>
            <Button onClick={downloadCSV} loading={isDownloading}>
              CSV <DownloadOutlined></DownloadOutlined>
            </Button>
          </span>
        )}
      </Space>
    </div>
  )
}



export default TransactionButtons
