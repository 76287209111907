import React, { useState } from "react";
import axios from "axios";
import { Button, Space, message } from "antd";
import { DownloadOutlined } from "@ant-design/icons";

const RemittanceButton = ({ selectedRowKeys, onRefunded, filters, orders }) => {
  const [isDownloading, setIsDownloading] = useState(false);

  const bulkDownload = async () => {
    if (!selectedRowKeys.length) {
      message.warning("Please select at least one order to download.");
      return;
    }

    setIsDownloading(true);
    try {
      await axios.post(`/api/payout/bulk-download-advice-report`, {
        payoutIds: selectedRowKeys,
      });

      message.success(
        "Download started successfully!, Kindly check your email after 15-20 minutes"
      );
    } catch (error) {
      message.error("Failed to download advice report. Please try again.");
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <div style={{ marginBottom: 16, float: "right" }}>
      <Space>
        <span>
          <Button
            onClick={bulkDownload}
            disabled={!selectedRowKeys.length || isDownloading}
            loading={isDownloading}
          >
            <Space>
              Download
              <DownloadOutlined style={{ fontSize: "20px" }} />
            </Space>
          </Button>
        </span>
      </Space>
    </div>
  );
};

export default RemittanceButton;
