import React, { useState, useEffect, useCallback } from 'react'
import { Pagination, Row, Col, Collapse } from 'antd'
import moment from 'moment'
import { CaretRightOutlined } from '@ant-design/icons'
import axios from 'axios'

import OrdersList from '../Order/OrdersList'
import OrderButtons from '../Order/OrderButtons'
import CodStats from '../Order/CodStats'
import CodOrderFilters from '../Order/CodOrderFilters'

import { PERMISSIONS } from "../../constants/roles";
import { usePermissions } from "../App/PermissionContext"; 

const today = moment()
const threeMonthsAgo = moment().subtract(3, 'months')

const UserCodOrder = ({ merchantId, userId }) => {
  const { permissions } = usePermissions();
  const [orders, setOrders] = useState([])
  const [filters, setFilters] = useState({ 
    isRemittance: null, 
    completeDelivered: null, 
    startDate: threeMonthsAgo, 
    endDate: today, 
    codCurrencyCode: 'RM' 
  })
  const [pagination, setPagination] = useState({ currentPage: 1, limit: 10 })
  const [meta, setMeta] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [selectedRows, setSelectedRows] = useState([])

  const fetchOrders = useCallback(async () => {
    setIsLoading(true)

    const params = {
      userId,
      merchantId,
      activeOnly: true,
      codOnly: true,
      codCurrencyCode: filters.codCurrencyCode,
      ...(filters.startDate !== null) && { startDate: filters.startDate?.startOf('day')?.toISOString() },
      ...(filters.endDate !== null) && { endDate: filters.endDate?.endOf('day')?.toISOString() },
      ...(filters.isRemittance !== null) && { isRemittance: filters.isRemittance },
      ...(filters.isNextDayRemittance !== null) && { isNextDayRemittance: filters.isNextDayRemittance },
      ...(filters.completeDelivered !== null) && { completeDelivered: filters.completeDelivered },
      ...(filters.isCodRefunded !== null) && { isCodRefunded: filters.isCodRefunded },
      limit: pagination.limit,
      page: pagination.currentPage
    }

    const res = await axios.get("/api/orders", { params })

    setOrders(res.data.orders)
    setPagination(res.data.pagination)
    setMeta(res.data.meta)
    setIsLoading(false)
  }, [pagination.currentPage, pagination.limit, merchantId, filters])

  useEffect(() => {
    fetchOrders()
  }, [pagination.currentPage, pagination.limit, fetchOrders])

  return (
    <div>
      <CodStats 
        stats={meta} 
        currencyCode={filters.codCurrencyCode}
        onSelect={selected => setFilters({...filters, ...selected})}
       /> 

      <Collapse expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}>
        <Collapse.Panel header="Filters" key="filters">
          <CodOrderFilters
            filters={filters} 
            onFiltersChange={filters => setFilters(filters)} 
          />
        </Collapse.Panel>
      </Collapse>

      <Row
        className="mt-2 mb-2"
        justify="space-between"
        align="middle"
      >
        <OrderButtons
          selectedRowKeys={selectedRows}
          orders={orders}
          filters={{
            merchantId,
            activeOnly: true,
            codOnly: true,
            codCurrencyCode: filters.codCurrencyCode,
            ...(filters.startDate !== null && {
              startDate: filters.startDate?.startOf("day")?.toISOString(),
            }),
            ...(filters.endDate !== null && {
              endDate: filters.endDate?.endOf("day")?.toISOString(),
            }),
            ...(filters.isRemittance !== null && {
              isRemittance: filters.isRemittance,
            }),
            ...(filters.isNextDayRemittance !== null && {
              isNextDayRemittance: filters.isNextDayRemittance,
            }),
            ...(filters.completeDelivered !== null && {
              completeDelivered: filters.completeDelivered,
            }),
            ...(filters.isCodRefunded !== null && {
              isCodRefunded: filters.isCodRefunded,
            }),
            pagination: pagination,
          }}
          onRefunded={() => fetchOrders()}
          
        />
        

        <Col>
          <Pagination
            total={pagination?.totalSize}
            pageSize={pagination?.limit}
            onChange={(currentPage, limit) => {
              setPagination({ ...pagination, limit, currentPage });
            }}
            current={pagination.currentPage}
          />
        </Col>
      </Row>

      <OrdersList 
        orders={orders} 
        onSelectRows={selectedRowKeys => setSelectedRows(selectedRowKeys)}
        selectedRows={selectedRows}
        loading={isLoading}
        
      />

      <Row justify="end">
        <Col>
          <Pagination 
            total={pagination?.totalSize} 
            pageSize={pagination?.limit}
            onChange={((currentPage, limit) => { setPagination({ ...pagination, limit, currentPage }) })}
            current={pagination.currentPage} 
          />
        </Col>
      </Row>
    </div>
  )
}

export default UserCodOrder