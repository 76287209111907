import React from "react";
import axios from "axios";
import { Checkbox, Divider, message } from "antd";
import { useState, useEffect } from "react";

import { BETA_FEATURES } from "../../constants/betaFeatures";

import { PERMISSIONS } from "../../constants/roles";
import { usePermissions } from "../App/PermissionContext"; 

const CheckboxGroup = Checkbox.Group;
const betaFeatureOptions = Object.values(BETA_FEATURES).map(feature => feature.label);

const UserBetaFeatures = ({ userId, betaFeatures}) => {
  const { permissions } = usePermissions();
  
  const [checkedList, setCheckedList] = useState(betaFeatures);
  const [checkAll, setCheckAll] = useState(
    betaFeatures.length === betaFeatureOptions.length
  );
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    setDisabled(!permissions?.includes(PERMISSIONS.UPDATE_BETA_FEATURES));
  }, [permissions]); 

  const onChange = async (list) => {
    setCheckedList(list);
    setCheckAll(list.length === betaFeatureOptions.length);

    try {
      await axios.put(`/api/user/${userId}/beta-features`, {
        betaFeatures: list,
      });

      message.info("Updated");
    } catch (err) {
      message.error("Error when updating User Beta features.");
    }
  };
  const onCheckAllChange = async (e) => {
    setCheckedList(e.target.checked ? betaFeatureOptions : []);
    setCheckAll(e.target.checked);

    const betaFeatures = e.target.checked ? betaFeatureOptions : [];

    try {
      await axios.put(`/api/user/${userId}/beta-features`, {
        betaFeatures: betaFeatures,
      });

      message.info("Updated");
    } catch (err) {
      message.error("Error when updating User Beta features.");
    }
  };

  const label = `${checkAll ? "Enable All" : "Disable All"}`;
  return (
    <>
      <Divider />
      <Checkbox
        checked={checkAll}
        disabled={disabled}
        onChange={onCheckAllChange}
      >
        {label}
      </Checkbox>
      <Divider />
      <CheckboxGroup
        options={betaFeatureOptions}
        value={checkedList}
        onChange={onChange}
        disabled={disabled}
      />
    </>
  );
};

export default UserBetaFeatures;
