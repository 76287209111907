import React, { useState, useEffect, useCallback } from "react";
import { Col, Row, Pagination, Collapse, Form } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import axios from "axios";
import PageHeader from "../Layout/PageHeader";
import moment from "moment";
import AccountManagerList from "./AccountManagerList";
import AccountManagerFilter from "./AccountManagerFilter";

const endOfMonth = moment().endOf("month");
const startOfMonth = moment().startOf("month");

const AccountManager = ({permission}) => {
  const [accountManager, setAccountManager] = useState();
  const [filters, setFilters] = useState({
    topupStartDate: startOfMonth,
    topupEndDate: endOfMonth,
    startDate: startOfMonth,
    endDate: endOfMonth,
  });
  const [selectedRows, setSelectedRows] = useState([]);
  const [pagination, setPagination] = useState({ currentPage: 1, limit: 10 });
  const [isLoading, setIsLoading] = useState(false);

  const fetchManager = useCallback(async () => {
    setIsLoading(true);
    const params = {
      isAdmin: true,
      ...(filters.topupStartDate !== null && {
        topupStartDate: filters.topupStartDate?.startOf("day")?.toISOString(),
      }),
      ...(filters.topupEndDate !== null && {
        topupEndDate: filters.topupEndDate?.endOf("day")?.toISOString(),
      }),
      ...(filters.startDate !== null && {
        startDate: filters.startDate?.startOf("day")?.toISOString(),
      }),
      ...(filters.endDate !== null && {
        endDate: filters.endDate?.endOf("day")?.toISOString(),
      }),
      ...(filters.search && {
        searchField: filters.searchField,
        search: filters.search,
      }),
      limit: pagination.limit,
      page: pagination.currentPage,
    };

    const res = await axios.get("/api/account-manager", { params });
    setAccountManager(res.data.data);
    setPagination(res.data.pagination);
    setIsLoading(false);
  }, [pagination.currentPage, pagination.limit, filters]);

  useEffect(() => {
    fetchManager();
  }, [pagination.currentPage, pagination.limit, fetchManager]);

  return (
    <div>
      <PageHeader
        title="Account Manager"
        childRoutes={[
          { path: "/account-manager", breadcrumbName: "Account Manager" },
        ]}
      />
      <Collapse
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
      >
        <Collapse.Panel header="Filters" key="filters">
          <AccountManagerFilter
            filters={filters}
            onFiltersChange={(filters) => setFilters(filters)}
          />
        </Collapse.Panel>
      </Collapse>
      <br />
      <AccountManagerList
        accountManager={accountManager}
        selectedRowKeys={selectedRows}
        onSelectRows={(selectedRowKeys) => setSelectedRows(selectedRowKeys)}
        loading={isLoading}
      />

      <Row justify="end">
        <Col>
          <Pagination
            total={pagination?.totalSize}
            pageSize={pagination?.limit}
            onChange={(currentPage, limit) => {
              setPagination({ ...pagination, limit, currentPage });
            }}
            current={pagination.currentPage}
          />
        </Col>
      </Row>
    </div>
  );
};

export default AccountManager;
