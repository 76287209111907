import React, { useState } from "react";
import {
  Button,
  Col,
  Row,
  Breadcrumb,
  Switch,
  Space,
  Alert,
  Typography,
  Collapse,
} from "antd";
import axios from "axios";
import { DownloadOutlined, CaretRightOutlined } from "@ant-design/icons";

import "./Remittance.css";
import "react-vis/dist/style.css";
import DragUpload from "../Form/DragUpload";

import { usePermissions } from "../App/PermissionContext";
import { PERMISSIONS } from "../../constants/roles";

const downloadTemplate = async () => {
  try {
    const { data } = await axios({
      url: "api/order/template/downloadRemittance",
      method: "GET",
      responseType: "blob",
    });
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "template_remittance.xlsx");
    document.body.appendChild(link);
    link.click();
  } catch (err) {
    console.log("err", err);
  }
};

const Remittance = () => {
  const { permissions } = usePermissions();

  const [isRemittance, setIsRemittance] = useState(true);

  return (
    <Space direction="vertical" style={{ display: "flex" }}>
      <div className="header">
        <Row>
          <Col span={12}>
            <h2 className="detail-header-text">Remittance</h2>
          </Col>
          <Col span={12}>
            <Breadcrumb style={{ margin: "10px 0", textAlign: "right" }} />
          </Col>
        </Row>
      </div>
      {permissions.includes(PERMISSIONS.UPDATE_REMITTANCE_STATUS) && (
        <Collapse
          expandIcon={({ isActive }) => (
            <CaretRightOutlined rotate={isActive ? 90 : 0} />
          )}
          activeKey={"upload"}
        >
          <Collapse.Panel
            header="Upload to manually update Orders' Remittance statuses"
            key="upload"
          >
            <Space
              style={{ display: "flex", alignItems: "middle" }}
              className="mb-2"
            >
              Remitted:{" "}
              <Switch
                size="large"
                checked={isRemittance}
                onChange={(checked) => setIsRemittance(checked)}
              />
              {isRemittance === false && (
                <Alert
                  message="This will mark the uploaded Order's status to NOT REMITTED."
                  showIcon
                  type="warning"
                />
              )}
            </Space>

            <DragUpload
              action={`${process.env.REACT_APP_SERVER_URL}/api/orders/remittance?isRemittance=${isRemittance}`}
              accept=".xls, .xlsx"
              method="put"
            />

            <Button onClick={downloadTemplate} className="mt-2">
              <DownloadOutlined /> Template
            </Button>
          </Collapse.Panel>
        </Collapse>
      )}
    </Space>
  );
};

export default Remittance;
