import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Table,
  Tooltip,
  Space,
  Switch,
  message,
  Pagination,
  Col,
  Row,
} from "antd";
import { formatDateTime } from "../../helpers/date";
import { usePermissions } from "../App/PermissionContext"; 
import { PERMISSIONS } from "../../constants/roles";

const QuestionaireList = (props) => {
  const { permissions } = usePermissions();

  const [isLoading, setIsLoading] = useState(false);
  const [questionaires, setQuestionaires] = useState(props.list);
  const [list, setList] = useState();
  const [pagination, setPagination] = useState({ currentPage: 1, limit: 10 });
  const [filters, setFilters] = useState({});

  useEffect(() => {
    const fetchQuestionaire = async () => {
      const params = {
        limit: pagination.limit,
        page: pagination.currentPage,
      };

      const res = await axios.get(`/api/questionaire/list`, { params });
      setList(res.data.data);
      setPagination(res.data.pagination);
    };

    fetchQuestionaire();
  }, [pagination.currentPage, pagination.limit, filters]);

  const toggleStatus = (questionaire, status) => {
    setIsLoading(true);

    axios
      .post(`/api/questionaire/status/${questionaire.objectId}`, {
        status,
      })
      .then((res) => {
        const updatedQuestionaires = list.map((q) => {
          if (q.objectId === questionaire.objectId) {
            q.isActive = status;
          }
        });
        setQuestionaires(updatedQuestionaires);

        list.map((q) => {
          if (q.objectId === questionaire.objectId) {
            q.isActive = status;
          }
        });
        message.info(
          `Questionaire (${questionaire.objectId}) has been ${
            status ? "Open" : "Closed"
          }`
        );
      })
      .catch((error) => {
        message.error(error.response || error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const columns = [
    {
      title: "Survey Title",
      dataIndex: ["questions", "title"],
      render: (text, row) => (
        <a href={`/questionaire/result/${row.objectId}`}>{text}</a>
      ),
    },
    {
      title: "Created At (YYYY-MM-DD)",
      dataIndex: "createdAt",
      render: (text) => (text ? formatDateTime(text) : "-"),
    },
    {
      title: "Status",
      dataIndex: "isActive",
      render: (text) => (text === true ? "Active" : "Inactive"),
    },
     ...(permissions?.includes(PERMISSIONS.UPDATE_QUESTIONAIRE_STATUS) ? [{
      title: "Open Status",
      dataIndex: "action",
      render: (_, row) => (
        <React.Fragment>
          <Tooltip placement="topLeft" title={`Toggle Active State`}>
            <Space direction="vertical">
              <Switch
                checkedChildren="Open"
                unCheckedChildren="Closed"
                checked={row.isActive}
                loading={isLoading}
                onChange={(status) => toggleStatus(row, status)}
              />
            </Space>
          </Tooltip>
        </React.Fragment>
      ),
      fixed: "right",
    },]:[])
  ];

  return (
    <div className="table">
      <Table
        rowKey="objectId"
        className="table-section"
        columns={columns}
        dataSource={list}
        pagination={false}
        scroll={{ x: true }}
      />
      <Col>
        <Pagination
          total={pagination?.totalSize}
          pageSize={pagination?.limit}
          onChange={(currentPage, limit) => {
            setPagination({ ...pagination, limit, currentPage });
          }}
          current={pagination.currentPage}
        />
      </Col>
    </div>
  );
};

export default QuestionaireList;
