import React from "react";
import { Col, Row, Breadcrumb, Space, Collapse, notification, Button } from "antd";
import { CaretRightOutlined, DownloadOutlined } from "@ant-design/icons";
import axios from 'axios'
import { PERMISSIONS } from "../../constants/roles";
import { usePermissions } from "../App/PermissionContext"; 

import DragUpload from "../Form/DragUpload";

const uploadSuccessHandler = () => {
  notification.open({
    message: 'Processing',
    description: "The downloads are under processing. Please check your email later.",
  });
}

const downloadTemplate = async () => {
  try {
    const { data } = await axios({
      url: "api/order/template/downloadRemittance",
      method: "GET",
      responseType: "blob",
    });
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "template_bulk-consign-notes.xlsx");
    document.body.appendChild(link);
    link.click();
  } catch (err) {
    console.log("err", err);
  }
}

const BulkDownloadConsignNote = () => {
  const { permissions } = usePermissions();
  
  return (
    <Space direction="vertical" style={{ display: 'flex' }}>
      <div className="header">
        <Row>
          <Col span={12}>
            <h2 className="detail-header-text">Bulk Download Consign Note</h2>
          </Col>
          <Col span={12}>
            <Breadcrumb
            style={{ margin: "10px 0", textAlign: "right" }}
          />
          </Col>
        </Row>
      </div>
      {permissions?.includes(PERMISSIONS.BULK_DOWNLOAD_CONNOTE) && (
        <Collapse
          expandIcon={({ isActive }) => (
            <CaretRightOutlined rotate={isActive ? 90 : 0} />
          )}
        >
          <Collapse.Panel header="Upload Tracking No" key="upload">
            <DragUpload
              action={`${process.env.REACT_APP_SERVER_URL}/api/consign-notes/bulk-download`}
              accept=".xls, .xlsx"
              method="post"
              onUploadSuccess={uploadSuccessHandler}
            />

            <Button onClick={downloadTemplate} className="mt-2">
              <DownloadOutlined /> Template (only Tracking Number column
              required)
            </Button>
          </Collapse.Panel>
        </Collapse>
      )}
    </Space>
  )
}

export default BulkDownloadConsignNote;
