import React, { useState } from "react";
import axios from "axios";
import moment from "moment";
import { Button, Space, Modal, message } from "antd";
import { DownloadOutlined, DeleteFilled } from "@ant-design/icons";
import { usePermissions } from "../App/PermissionContext";
import { PERMISSIONS } from "../../constants/roles";

const UserButtons = ({ selectedRowKeys, users, filters, pagination }) => {
  const { permissions } = usePermissions();
  const [isDownloading, setIsDownloading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const downloadAllUserCSV = async () => {
    const queryString = new URLSearchParams(filters).toString();

    const messageContent = (
      <p>
        Are you sure you want to download {pagination.pagination.totalSize} user
        data ?<br />
      </p>
    );

    Modal.confirm({
      content: messageContent,
      title: `Download User List ? `,
      async onOk() {
        await axios
          .get(
            `/api/users/csv?total=${pagination.pagination.totalSize}&${queryString}`
          )
          .then((res) => {
            message.info(`Please check your email after 5-20 minute`);
          })
          .catch((error) => {
            message.error(error.message);
          });
      },
    });
  };

  const lockUser = async () => {
    setIsLoading(true);
    selectedRowKeys.forEach(async (row) => {
      const postJson = {
        merchantId: row,
        lockedTier: true,
      };
      await axios
        .patch("api/merchant/lock/tier", postJson)
        .then(function (response) {
          message.info("Changes Updated");
        });
    });
    setIsLoading(false);
  };

  const unlockUser = async () => {
    //const data = this.state.data;
    selectedRowKeys.forEach(async (row) => {
      console.log("The row", row);
      const postJson = {
        merchantId: row,
        lockedTier: false,
      };
      await axios
        .patch("api/merchant/lock/tier", postJson)
        .then(function (response) {
          message.info("Changes Updated");
        });
    });
  };
  return (
    <div style={{ marginBottom: 16, float: "right" }}>
      <Space>
        {permissions?.includes(PERMISSIONS.EXPORT_LIST) && (
          <span>
            <Button onClick={downloadAllUserCSV} loading={isDownloading}>
              CSV <DownloadOutlined></DownloadOutlined>
            </Button>
          </span>
        )}
        <span>
          {selectedRowKeys.length > 0 && (
            <Button
              onClick={() => {
                unlockUser(selectedRowKeys);
              }}
            >
              Unlock Tier
            </Button>
          )}
        </span>
        <span>
          {selectedRowKeys.length > 0 && (
            <Button
              onClick={() => {
                lockUser(selectedRowKeys);
              }}
            >
              Lock Tier
            </Button>
          )}
        </span>
        <span>
          {selectedRowKeys.length > 0 && (
            <Button>
              <DeleteFilled className="icons" />
            </Button>
          )}
        </span>
      </Space>
    </div>
  );
};

export default UserButtons;
