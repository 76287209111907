import React, { useState, useCallback, useEffect } from 'react'
import { Row, Col, Pagination, Collapse, message, Button, Modal } from "antd"
import axios from "axios";
import { CaretRightOutlined, DownloadOutlined } from '@ant-design/icons'

import PageHeader from '../Layout/PageHeader'
import WeightDisputeByMerchantList from './WeightDisputeByMerchantList';
import WeightDisputeByMerchantFilters from './WeightDisputeByMerchantFilters';

import { PERMISSIONS } from "../../constants/roles";
import { usePermissions } from "../App/PermissionContext"; 

const WeightDisputeByMerchant = () => {
  const { permissions } = usePermissions();
  
  const [loading, setLoading] = useState({ index: false, export: false })
  const [weightDisputes, setWeightDisputes] = useState([])
  const [filters, setFilters] = useState({ })
  const [pagination, setPagination] = useState({ currentPage: 1, limit: 50 })

  const fetchWeightDisputes = useCallback(async () => {
    setLoading(prevLoading => ({ ...prevLoading, index: true }))

    const params = {
      limit: pagination.limit,
      page: pagination.currentPage,
      ...(filters.search && { searchField: filters.searchField, search: filters.search }),
      startDate: filters.startDate?.startOf('day')?.toISOString(),
      endDate: filters.endDate?.endOf('day')?.toISOString(),
    }
    const res = await axios.get("/api/weight-disputes/merchants", { params }) 

    setWeightDisputes(res.data.data || [])
    setPagination(res.data.pagination)
    setLoading(prevLoading => ({ ...prevLoading, index: false }))
  }, [pagination.currentPage, pagination.limit, filters])

  const exportDownload = async () => {
    setLoading(prevLoading => ({ ...prevLoading, export: true }))

    const params = {
      ...(filters.search && { searchField: filters.searchField, search: filters.search }),
      startDate: filters.startDate?.startOf('day')?.toISOString(),
      endDate: filters.endDate?.endOf('day')?.toISOString(),
      limit: 10,
    }

    const messageContent = (
      <p>
        Are you sure you want to download {pagination.totalSize} data ?<br/>
      </p>
    );

    Modal.confirm({
      content: messageContent,
      title: `Download Weight Dispute by Merchant List ? `,
      async onOk() {
        await axios
        .get("/api/weight-disputes/merchants", { params, headers: { 'accept': 'application/vnd.ms-excel' } })
          .then((res) => {
            message.info(`Please check your email after 5-20 minute`);
          })
          .catch((error) => {
            message.error(error.message);
          });
      },
    });
  
    setLoading(prevLoading => ({ ...prevLoading, export: false }))
  }

  useEffect(() => {
    fetchWeightDisputes()
  }, [pagination.currentPage, pagination.limit, fetchWeightDisputes])

  return (
    <div>
      <PageHeader 
        title="Weight Dispute By Merchant"
        childRoutes={[{ path: "/weight-dispute/merchant", breadcrumbName: "Weight Dispute By Merchant" }]}
      />

      <Collapse expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}>
        <Collapse.Panel header="Filters" key="filters">
          <WeightDisputeByMerchantFilters
            filters={filters} 
            onFiltersChange={filters => setFilters(filters)} 
          />
        </Collapse.Panel>
      </Collapse>

      <Row
        className="mt-2 mb-2"
        justify={
          permissions?.includes(PERMISSIONS.EXPORT_LIST)
            ? "space-between"
            : "start"
        }
        align="middle"
      >
        <Col>
          <Pagination
            total={pagination?.totalSize}
            pageSize={pagination?.limit}
            onChange={(currentPage, limit) => {
              setPagination({ ...pagination, limit, currentPage });
            }}
            current={pagination.currentPage}
          />
        </Col>
        {permissions?.includes(PERMISSIONS.EXPORT_LIST) && (
          <span color="blue">
            <Button onClick={exportDownload} loading={loading.export}>
              Export <DownloadOutlined></DownloadOutlined>
            </Button>
          </span>
        )}
      </Row>
      
        <WeightDisputeByMerchantList
          weightDisputes={weightDisputes}
          loading={loading.index}
        />
    </div>
  )
}

export default WeightDisputeByMerchant