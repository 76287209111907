import React, { useState, useEffect } from "react"
import { Table, Space, Tooltip, Button, InputNumber, DatePicker, Switch } from 'antd'
import { SaveOutlined } from '@ant-design/icons';
import moment from "moment";

import DiscountCodeRedemptionList from "./DiscountCodeRedemptionList";

import { PERMISSIONS } from "../../constants/roles";
import { usePermissions } from "../App/PermissionContext"; 

import { formatDateTime } from "../../helpers/date";

const DiscountCodeList = props =>{

  const { permissions } = usePermissions();
  
  const [discountCodes, setDiscountCodes] = useState(props.discountCodes)

  useEffect(() => {
    setDiscountCodes(props.discountCodes);
  }, [props.discountCodes])

  const updateHandler = id => {
    const updated = discountCodes.find(discountCode => discountCode.objectId === id)

    props.onUpdate(id, updated)
  }
  const table = [
    {
      title: "Discount Code",
      dataIndex: "code",
    },
    {
      title: "Total Redeemed",
      dataIndex: "totalRedeemed",
      render: (_, record) => `${record.totalRedeemedTimes} (RM ${record.totalRedeemedAmount?.toFixed(2) || '-' })`
    },
    {
      title: "Minimum Order Value (RM)",
      dataIndex: "minimumOrderValue",
      render: (text, record) =>
        permissions?.includes(PERMISSIONS.UPDATE_DISCOUNT_CODE) ? (
          <InputNumber
            value={text}
            precision={2}
            min={1}
            onChange={(value) => {
              setDiscountCodes(
                discountCodes.map((discountCode) =>
                  discountCode.objectId === record.objectId
                    ? { ...discountCode, minimumOrderValue: value }
                    : discountCode
                )
              );
            }}
          />
        ) : (
          text
        ),
    },
    {
      title: "Discount Value (RM)",
      dataIndex: "discountValue",
      render: (text, record) =>
        permissions?.includes(PERMISSIONS.UPDATE_DISCOUNT_CODE) ? (
          <InputNumber
            value={text}
            precision={2}
            onChange={(value) => {
              setDiscountCodes(
                discountCodes.map((discountCode) =>
                  discountCode.objectId === record.objectId
                    ? { ...discountCode, discountValue: value }
                    : discountCode
                )
              );
            }}
          />
        ) : (
          text
        ),
    },
    {
      title: "Redeem Times",
      dataIndex: ["redeemTimes"],
      render: (text, record) =>
        permissions?.includes(PERMISSIONS.UPDATE_DISCOUNT_CODE) ? (
          <InputNumber
            value={text}
            precision={0}
            onChange={(value) => {
              setDiscountCodes(
                discountCodes.map((discountCode) =>
                  discountCode.objectId === record.objectId
                    ? { ...discountCode, redeemTimes: value }
                    : discountCode
                )
              );
            }}
          />
        ) : (
          text
        ),
    },
    {
      title: "Validity Period",
      dataIndex: "validityPeriod",
      render: (_, record) =>
        permissions?.includes(PERMISSIONS.UPDATE_DISCOUNT_CODE) ? (
          <DatePicker.RangePicker
            value={[moment(record.validFrom.iso), moment(record.validTo.iso)]}
            onChange={(dates) => {
              setDiscountCodes(
                discountCodes.map((discountCode) =>
                  discountCode.objectId === record.objectId
                    ? {
                        ...discountCode,
                        validFrom: { iso: dates[0].startOf("day").toDate() },
                        validTo: { iso: dates[1].endOf("day").toDate() },
                      }
                    : discountCode
                )
              );
            }}
          />
        ) : (
          <div>
            {formatDateTime(record.validFrom)} -{" "}
            {formatDateTime(record.validTo)}
          </div>
        ),
    },
    {
      title: "Active",
      dataIndex: "isActive",
      render: (text, record) =>
        permissions?.includes(PERMISSIONS.UPDATE_DISCOUNT_CODE) ? (
          <Switch
            checked={text}
            onChange={(checked) => {
              setDiscountCodes(
                discountCodes.map((discountCode) =>
                  discountCode.objectId === record.objectId
                    ? { ...discountCode, isActive: checked }
                    : discountCode
                )
              );
            }}
          />
        ) : text === true ? (
          "Active"
        ) : (
          "Inactive"
        ),
    },
    ...(permissions?.includes(PERMISSIONS.UPDATE_DISCOUNT_CODE)
      ? [
          {
            title: "",
            dataIndex: "action",
            fixed: "right",
            render: (_, record) => (
              <Space>
                <Tooltip title="Update">
                  <Button
                    type="primary"
                    icon={<SaveOutlined />}
                    onClick={() => updateHandler(record.objectId)}
                  />
                </Tooltip>
              </Space>
            ),
          },
        ]
      : []),
  ];

  return (
      <div className="table">
        <Table
          rowKey="objectId"
          className="table-section"
          columns={table}
          expandable={{
            expandedRowRender: (record) => <DiscountCodeRedemptionList discountCode={record} />,
          }}
          dataSource={discountCodes}
          pagination={false}
          scroll={{ x: true }}
          loading={props.loading}
        />
      </div>
    )
}

export default DiscountCodeList
