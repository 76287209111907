import React, { useState } from "react";
import { Select, DatePicker, Space, Input, Row, Col } from "antd";
import {
  USER_BADGES,
  USER_STATUS,
  USER_PACKAGES,
  USER_TAGGING,
} from "../../constants/user";

const UserFilters = ({ filters, onFiltersChange, tabName }) => {
  const [searchField, setSearchField] = useState("email");
  const searchBy = (
    <Select
      defaultValue="email"
      className="select-before"
      onChange={(value) => setSearchField(value)}
    >
      <Select.Option value="email">User Email</Select.Option>
      <Select.Option value="username">Username</Select.Option>
      <Select.Option value="phone">Phone</Select.Option>
    </Select>
  );

  const isDangerUserTab = tabName === "dangerUser";
  const dateFilterLabel = isDangerUserTab
    ? "RTO Date Range"
    : "Email Verified On";

  return (
    <Space size="large" direction="vertical" style={{ width: "100%" }}>
      <Row gutter={16}>
        <Col span={6}>
          <div>
            {dateFilterLabel} :
            <DatePicker.RangePicker
              value={[filters.startDate, filters.endDate]}
              style={{ width: "100%" }}
              onChange={(dates) =>
                dates
                  ? onFiltersChange({
                      ...filters,
                      startDate: dates[0],
                      endDate: dates[1],
                    })
                  : onFiltersChange({
                      ...filters,
                      startDate: undefined,
                      endDate: undefined,
                    })
              }
            />
          </div>
        </Col>
        {!isDangerUserTab && (
          <>
            <Col span={6}>
              <div>
                Last Active Date :
                <DatePicker.RangePicker
                  style={{ width: "100%" }}
                  value={[
                    filters.startLastActiveDate,
                    filters.endLastActiveDate,
                  ]}
                  onChange={(dates) =>
                    dates
                      ? onFiltersChange({
                          ...filters,
                          startLastActiveDate: dates[0],
                          endLastActiveDate: dates[1],
                        })
                      : onFiltersChange({
                          ...filters,
                          startLastActiveDate: undefined,
                          endLastActiveDate: undefined,
                        })
                  }
                />
              </div>
            </Col>
            <Col span={6}>
              <div>
                Last Topup Date :
                <DatePicker.RangePicker
                  style={{ width: "100%" }}
                  value={[filters.topupStartDate, filters.topupEndDate]}
                  onChange={(dates) =>
                    dates
                      ? onFiltersChange({
                          ...filters,
                          topupStartDate: dates[0],
                          topupEndDate: dates[1],
                        })
                      : onFiltersChange({
                          ...filters,
                          topupStartDate: undefined,
                          topupEndDate: undefined,
                        })
                  }
                />
              </div>
            </Col>
          </>
        )}
        <Col span={6}>
          <div>
            Last Order Date :
            <DatePicker.RangePicker
              style={{ width: "100%" }}
              value={[filters.startLastOrderDate, filters.endLastOrderDate]}
              onChange={(dates) =>
                dates
                  ? onFiltersChange({
                      ...filters,
                      startLastOrderDate: dates[0],
                      endLastOrderDate: dates[1],
                    })
                  : onFiltersChange({
                      ...filters,
                      startLastOrderDate: undefined,
                      endLastOrderDate: undefined,
                    })
              }
            />
          </div>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={6}>
          <div>
            Search :
            <Input.Search
              addonBefore={searchBy}
              placeholder="Search"
              onSearch={(term) =>
                onFiltersChange({ ...filters, search: term, searchField })
              }
            />
          </div>
        </Col>
        <Col span={6}>
          <div>
            Badge :
            <Select
              placeholder="Select"
              style={{ width: "100%" }}
              allowClear
              onChange={(value) =>
                onFiltersChange({ ...filters, badge: value })
              }
            >
              {Object.entries(USER_BADGES).map(([key, value]) => (
                <Select.Option key={key} value={key}>
                  {value}
                </Select.Option>
              ))}
            </Select>
          </div>
        </Col>
        {!isDangerUserTab && (
          <>
            <Col span={6}>
              <div>
                Status :
                <Select
                  placeholder="Select"
                  style={{ width: "100%" }}
                  allowClear
                  onChange={(value) =>
                    onFiltersChange({ ...filters, status: value })
                  }
                >
                  {Object.entries(USER_STATUS).map(([key, value]) => (
                    <Select.Option key={key} value={key}>
                      {value}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </Col>
            <Col span={6}>
              <div>
                Package :
                <Select
                  placeholder="Select"
                  style={{ width: "100%" }}
                  allowClear
                  onChange={(value) =>
                    onFiltersChange({ ...filters, topupPackage: value })
                  }
                >
                  {Object.entries(USER_PACKAGES).map(([key, value]) => (
                    <Select.Option key={key} value={key}>
                      {value}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </Col>
          </>
        )}
      </Row>

      <Row gutter={16}>
        <Col span={6}>
          <div>
            Tagging :
            <Select
              placeholder="Select"
              style={{ width: "100%" }}
              allowClear
              onChange={(value) =>
                onFiltersChange({ ...filters, tagging: value })
              }
            >
              {Object.entries(USER_TAGGING).map(([key, value]) => (
                <Select.Option key={key} value={key}>
                  {value}
                </Select.Option>
              ))}
            </Select>
          </div>
        </Col>
      </Row>
    </Space>
  );
};

export default UserFilters;
