import React, { useState, useEffect, useCallback } from "react";
import PageHeader from "../Layout/PageHeader";
import "react-vis/dist/style.css";
import moment from "moment";
import UserFilters from "../User/UserFilters";
import UserBadgeTag from "../shared/UserBadgeTag";
import AccountManagerTopup from "./AccountManagerTopup";
import TopupPackageTag from "../shared/TopupPackageTag";
import AccountManagerList from "./AccountManagerList";
import AccountManagerFilter from "./AccountManagerFilter";
import axios from "axios";
import { Row, Col, Tabs, Table, Collapse, Pagination } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";

const AccountManagerDetails = ({permission}) => {
  const { id } = useParams();
  const [filters, setFilters] = useState({});
  const [userList, setUserList] = useState();
  const [selectedRows, setSelectedRows] = useState([]);
  const [pagination, setPagination] = useState({ currentPage: 1, limit: 10 });
  const [isLoading, setIsLoading] = useState(false);
  const fetchUser = useCallback(async () => {
    setIsLoading(true);
    const params = {
      accountManagerId: id,
      ...(filters.startDate !== null && {
        startDate: filters.startDate?.startOf("day")?.toISOString(),
      }),
      ...(filters.endDate !== null && {
        endDate: filters.endDate?.endOf("day")?.toISOString(),
      }),
      ...(filters.search && {
        searchField: filters.searchField,
        search: filters.search,
      }),
      limit: pagination.limit,
      page: pagination.currentPage,
    };

    const res = await axios.get(`/api/users`, { params });

    setUserList(res.data.data);
    setPagination(res.data.pagination);
    setIsLoading(false);
  }, [pagination.currentPage, pagination.limit, filters]);
  useEffect(() => {
    fetchUser();
  }, [pagination.currentPage, pagination.limit, fetchUser]);

  return (
    <div>
      <PageHeader
        title="Account Manager Details"
        childRoutes={[
          { path: "/account-manager", breadcrumbName: "Account Manager" },
        ]}
      />

      <div className="table mt-4">
        <Tabs defaultActiveKey="1" animated={true}>
          <Tabs.TabPane tab="All User" key="all-transactions">
            <Collapse
              expandIcon={({ isActive }) => (
                <CaretRightOutlined rotate={isActive ? 90 : 0} />
              )}
            >
              <Collapse.Panel header="Filters" key="filters">
                <AccountManagerFilter
                  filters={filters}
                  onFiltersChange={(filters) => setFilters(filters)}
                  tabName="allUser"
                />
              </Collapse.Panel>
            </Collapse>
            <br />
            <Row justify="end">
              <Col>
                <Pagination
                  total={pagination?.totalSize}
                  pageSize={pagination?.limit}
                  onChange={(currentPage, limit) => {
                    setPagination({ ...pagination, limit, currentPage });
                  }}
                  current={pagination.currentPage}
                />
              </Col>
            </Row>
            <AccountManagerList
              accountManager={userList}
              selectedRowKeys={selectedRows}
              onSelectRows={(selectedRowKeys) =>
                setSelectedRows(selectedRowKeys)
              }
              name="user"
              loading={isLoading}
            />

            <Row justify="end">
              <Col>
                <Pagination
                  total={pagination?.totalSize}
                  pageSize={pagination?.limit}
                  onChange={(currentPage, limit) => {
                    setPagination({ ...pagination, limit, currentPage });
                  }}
                  current={pagination.currentPage}
                />
              </Col>
            </Row>
          </Tabs.TabPane>

          <Tabs.TabPane tab="Topup" key="weightDispute">
            <AccountManagerTopup accountManagerId={id} />
          </Tabs.TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default AccountManagerDetails;
