import React, { useState } from 'react'
import { InputNumber, Button, Form, Modal, Select, Divider } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { SERVICE_PROVIDER_DETAILS } from '../../constants/serviceProviders'
import { PERMISSIONS } from "../../constants/roles";
import { usePermissions } from "../App/PermissionContext"; 

const CourierSettings = ({ settings, onSave }) => {

  const { permissions } = usePermissions();

  const [saving, setSaving] = useState(false)
  const [form] = Form.useForm();

  const saveHandler = async (newSettings) => {
    setSaving(true)

    Modal.confirm({
      title: 'Confirm to update the courier settings?',
      icon: <ExclamationCircleOutlined />,
      onOk() {
        onSave('courier', newSettings).then(() => {
          form.resetFields()
          setSaving(false)
        })
      },
      onCancel() {
        setSaving(false)
      },
    });
  }

  return (
    <div>
      <Form
        form={form}
        name="courier-settings"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        autoComplete="off"
        initialValues={settings}
        requiredMark={false}
        onFinish={saveHandler}
      >
        <Form.Item
          extra={`Courier that will be highligted to customer in Dashboard`}
          label="Star Courier"
          name="highlight"
        >
          <Select
            placeholder="Select a option and change input text above"
            allowClear
            disabled={!permissions?.includes(PERMISSIONS.UPDATE_SETTING)} 
          >
            <Select.Option value={null}>Do not highlight any</Select.Option>
            { SERVICE_PROVIDER_DETAILS
                .filter(courier => courier.isPublic !== false)
                .map(courier => <Select.Option value={courier.key}>{courier.name}</Select.Option>) 
            }
          </Select>
        </Form.Item>

        <Divider>Courier Order</Divider>
        <p class="text-center">
            The order of Couriers being displayed in Dashboard. The lower the number, the earlier/higher they will be displayed.
        </p>

        { SERVICE_PROVIDER_DETAILS
            .filter(courier => courier.isPublic !== false)
            .map(courier => {
                return (
                    <Form.Item
                        label={courier.name}
                        name={['order', courier.key]}
                        rules={[{ required: true, message: 'Please input the order!' }]}
                    >
                        <InputNumber disabled={!permissions?.includes(PERMISSIONS.UPDATE_SETTING)} step={1} />
                    </Form.Item>
                )
            }) 
        }
        
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button disabled={!permissions?.includes(PERMISSIONS.UPDATE_SETTING)} type="primary" loading={saving} htmlType="submit">Save</Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default CourierSettings