import React, { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import {
  Table,
  Button,
  Dropdown,
  Menu,
  notification,
  Tooltip,
  Modal,
  message,
  Popover,
  Form,
  InputNumber,
} from "antd";
import {
  DownOutlined,
  LayoutOutlined,
  InfoCircleOutlined,
  FileOutlined,
  FileImageOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

import ServiceProviderTag from "../shared/ServiceProviderTag";

import { WEBHOOKLESS_SERVICE_PROVIDERS } from "../../constants/serviceProviders";

import { formatDateTime } from "../../helpers/date";
import { PERMISSIONS } from "../../constants/roles";
import { usePermissions } from "../App/PermissionContext"; 

const download = (id, type) => {
  const connoteType = type === "a4" ? "" : "Thermal";

  return axios.get("/consign-pdf/" + id + connoteType, {
    responseType: "blob",
  });
};

const OrdersList = ({
  orders,
  loading,
  selectedRows,
  onSelectRows,
  onCodRefunded,
  onMarkCancelled,
  showButtons = { download: true }
}) => {
  const { permissions } = usePermissions();
  
  const [isMessageVisible, setIsMessageVisible] = useState(false);
  const [messageData, setMessageData] = useState("");
  const [fileUrl, setFileUrl] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [fileType, setFileType] = useState("");
  const [isPodVisible, setIsPodVisible] = useState(false);
  const [imgUrl, setImgUrl] = useState("");
  const [cancelCod, setCancelCod] = useState(false);
  const [form] = Form.useForm();
  const [isLogVisible, setLogVisible] = useState(false);
  const [log, setLog] = useState([]);
  const [downloading, setDownloading] = useState(false);

  const handleDownload = (connote, connoteType) => {
    setDownloading(true);
    download(connote, connoteType)
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });

        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = connote + connoteType + ".pdf";
        link.click();
        URL.revokeObjectURL(link.href);
      })
      .catch(async (err) => {
        notification.error({
          message: `Error when bulk downloading consignment notes for ${connote}`,
          description: err.response?.data.message || err.message,
        });
      })
      .finally(() => {
        setDownloading(false);
      });
  };

  const createActionMenu = (row) => (
    <Menu>
      {permissions?.includes(PERMISSIONS.VIEW_POD) && (
        <Menu.SubMenu key="download" title="Download" icon={<DownOutlined />}>
          {showButtons.download && (
            <>
              <Menu.Item
                key="a4"
                icon={<LayoutOutlined />}
                onClick={() => handleDownload(row.consignNote, "A4")}
              >
                A4
              </Menu.Item>
              <Menu.Item
                key="thermal"
                icon={<LayoutOutlined />}
                onClick={() => handleDownload(row.consignNote, "Thermal")}
              >
                Thermal
              </Menu.Item>
            </>
          )}
        </Menu.SubMenu>
      )}

      {permissions?.includes(PERMISSIONS.VIEW_POD) && row.pod && (
        <Menu.Item
          key="pod"
          icon={<LayoutOutlined />}
          onClick={() => viewPOD(row)}
        >
          View POD
        </Menu.Item>
      )}
      {permissions?.includes(PERMISSIONS.VIEW_INVOICE) && row.invoicePath && (
        <Menu.Item
          key="pod"
          icon={<FileOutlined />}
          onClick={() => viewInvoice(row.orderId)}
        >
          View Invoice
        </Menu.Item>
      )}
      {permissions?.includes(PERMISSIONS.MARK_CANCEL_ORDER) &&
        showButtons.markCancelledByUser &&
        row.internalOrderStatus !== "Cancelled by User" && (
          <Menu.Item
            key="mark"
            icon={<LayoutOutlined />}
            onClick={() => markCancelledHandler(row)}
          >
            Mark Order as 'Cancelled by User'
          </Menu.Item>
        )}
      {permissions?.includes(PERMISSIONS.REFUND_COD) && showButtons.refundCod && (
        <Menu.Item
          key="mark"
          icon={<LayoutOutlined />}
          onClick={() => refundCodHandler(row)}
          disabled={!row.cod || row.isCodRefunded || !row.isReturnRequested}
        >
          Refund COD
        </Menu.Item>
      )}
      {permissions?.includes(PERMISSIONS.EDIT_COD) && (
        <Menu.SubMenu
          key="codSetting"
          title="Cod Setting"
          icon={<LayoutOutlined />}
        >
          {showButtons.download && (
            <>
              <Menu.Item
                key="a4"
                icon={<EditOutlined />}
                onClick={() => editOrderCod(row)}
              >
                Edit COD
              </Menu.Item>
              <Menu.Item
                key="thermal"
                icon={<DeleteOutlined />}
                onClick={() => cancelOrderCod(row)}
                disabled={!row.cod}
              >
                Cancel COD
              </Menu.Item>
            </>
          )}
        </Menu.SubMenu>
      )}

      {permissions?.includes(PERMISSIONS.VIEW_CHANGE_LOG) && row.originalCod && (
        <Menu.Item
          key="changeLog"
          icon={<FileImageOutlined />}
          onClick={() => viewChangesLog(row.orderId)}
        >
          View Cod Change Log
        </Menu.Item>
      )}
      {permissions?.includes(PERMISSIONS.SYNC_ORDER_STATUS) && (
        <Menu.Item
          key="changeLog"
          icon={<FileImageOutlined />}
          onClick={() => syncOrderStatus(row)}
          disabled={
            !WEBHOOKLESS_SERVICE_PROVIDERS.includes(row.serviceProvider) ||
            row.internalOrderStatus === "Refunded"
          }
        >
          Sync Order's Status
        </Menu.Item>
      )}
    </Menu>
  );

  const columns = [
    {
      title: "Email",
      dataIndex: ["merchant", "user", "email"],
      fixed: "left",
    },
    {
      title: "Consign Note",
      dataIndex: "consignNote",
      render: (text, row) =>
        text ? text : <Button type="dashed">{row.orderStatus}</Button>,
      fixed: "left",
    },
    {
      title: "Order Id",
      dataIndex: "orderId",
    },
    {
      title: "Username",
      dataIndex: ["merchant", "name"],
      render: (text, row) => (
        <Link to={`/user/${row?.merchant?.user.userId}`}>{text}</Link>
      ),
    },
    {
      title: "Successful Payment (YYYY-MM-DD)",
      dataIndex: ["payment", "successAt"],
      render: (text) => (text ? formatDateTime(text) : "-"),
    },
    {
      title: "Service Provider",
      dataIndex: "serviceProvider",
      render: (text) => <ServiceProviderTag serviceProviderKey={text} />,
    },
    {
      title: "Content",
      dataIndex: "content",
    },
    {
      title: "Content Value",
      dataIndex: "content_price",
    },
    {
      title: (
        <span>
          Original Weight{" "}
          <Tooltip title="Original Weight recorded during Checkout. This value will never change.">
            <InfoCircleOutlined />
          </Tooltip>
        </span>
      ),
      dataIndex: "snapshot",
      render: (snapshot) => snapshot?.kg || "-",
    },
    {
      title: (
        <span>
          Prev Weight{" "}
          <Tooltip title="Weight before the last Weight Dispute update.">
            <InfoCircleOutlined />
          </Tooltip>
        </span>
      ),
      dataIndex: "original",
      render: (original) => original?.kg || "-",
    },
    {
      title: (
        <span>
          Actual Weight{" "}
          <Tooltip title="Latest Weight.">
            <InfoCircleOutlined />
          </Tooltip>
        </span>
      ),
      dataIndex: "weight",
    },
    {
      title: (
        <span>
          Original Price{" "}
          <Tooltip title="Original Price recorded during Checkout. This value will never change.">
            <InfoCircleOutlined />
          </Tooltip>
        </span>
      ),
      dataIndex: "snapshot",
      render: (snapshot) => snapshot?.price || "-",
    },
    {
      title: (
        <span>
          Prev Price{" "}
          <Tooltip title="Price before the last Weight Dispute update.">
            <InfoCircleOutlined />
          </Tooltip>
        </span>
      ),
      dataIndex: "original",
      render: (original) => original?.price || "-",
    },
    {
      title: (
        <span>
          Actual Price{" "}
          <Tooltip title="Latest Price.">
            <InfoCircleOutlined />
          </Tooltip>
        </span>
      ),
      dataIndex: "price",
    },
    {
      title: "Status",
      dataIndex: "orderStatus",
    },
    {
      title: "Internal Status",
      dataIndex: "internalOrderStatus",
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Order Last Update",
      dataIndex: "orderLastUpdateStatusDiffDay",
      render: (text) => (text === 0 ? text + " Day ago" : text + " Days ago"),
    },
    {
      title: (
        <span>
          Original COD{" "}
          <Tooltip title="Original COD amount recorded during Checkout. This value will never change.">
            <InfoCircleOutlined />
          </Tooltip>
        </span>
      ),
      dataIndex: "snapshot",
      ellipsis: true,
      render: (snapshot) => {
        return snapshot?.cod
          ? `${snapshot?.codCurrency?.currency || "RM"} ${snapshot?.cod.toFixed(
              2
            )}`
          : "-";
      },
    },
    {
      title: "COD",
      dataIndex: "cod",
      ellipsis: true,
      render: (cod, order) => {
        if (cod && order.codCurrency) {
          const { currency, fromChargeRate, fromRemitRate } = order.codCurrency;
          const rateInfo = (
            <div>
              {fromChargeRate && (
                <p>
                  <strong>Charge Rate</strong>: {currency} 1.00 to RM{" "}
                  {fromChargeRate}
                </p>
              )}
              {fromRemitRate && (
                <p>
                  <strong>Remit Rate</strong>: {currency} 1.00 to RM{" "}
                  {fromRemitRate}
                </p>
              )}
            </div>
          );

          return (
            <div>
              <span>{`${currency} ${cod.toFixed(2)}`}</span>
              {` `}
              <Popover content={rateInfo} title="Conversion Rates">
                <InfoCircleOutlined />
              </Popover>
            </div>
          );
        }

        return cod ? `RM ${cod.toFixed(2)}` : "-";
      },
    },
    {
      title: "Pickup",
      dataIndex: "pickup",
    },
    {
      title: "Pickup Email",
      dataIndex: "pickupEmail",
    },
    {
      title: "Pickup Phone",
      dataIndex: "pickupPhone",
    },
    {
      title: "Pickup Address Line 1",
      dataIndex: ["pickupAddress", "line1"],
    },
    {
      title: "Pickup Address Line 2",
      dataIndex: ["pickupAddress", "line2"],
    },
    {
      title: "Pickup Address City",
      dataIndex: ["pickupAddress", "city"],
    },
    {
      title: "Pickup Address State",
      dataIndex: "pickupState",
    },
    {
      title: "Pickup Address Postcode",
      dataIndex: ["pickupAddress", "postcode"],
    },
    {
      title: "Receiver",
      dataIndex: "receiver",
    },
    {
      title: "Receiver Phone",
      dataIndex: ["receiverAddress", "phone"],
    },
    {
      title: "Receiver Address Line 1",
      dataIndex: ["receiverAddress", "line1"],
    },
    {
      title: "Receiver Address Line 2",
      dataIndex: ["receiverAddress", "line2"],
    },
    {
      title: "Receiver Address City",
      dataIndex: ["receiverAddress", "city"],
    },
    {
      title: "Receiver Address State",
      dataIndex: "receiverState",
    },
    {
      title: "Receiver Address Country",
      dataIndex: ["receiverAddress", "country"],
    },
    {
      title: "Receiver Address Postcode",
      dataIndex: ["receiverAddress", "postcode"],
    },
    {
      title: "Dropoff",
      dataIndex: "isDropoff",
      render: (text) => (text ? "True" : "-"),
    },
    {
      title: "Insurance",
      dataIndex: "insurance",
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Postage Price",
      dataIndex: ["prices", "courier", "postage"],
      render: (text) => (text ? Number(text).toFixed(2) : "-"),
    },
    {
      title: "SMS Charge",
      dataIndex: ["prices", "courier", "pickupSMS"],
      render: (text) => (text ? Number(text).toFixed(2) : "-"),
    },
    {
      title: "Email Charge",
      dataIndex: ["prices", "courier", "pickupEmail"],
      render: (text) => (text ? Number(text).toFixed(2) : "-"),
    },
    {
      title: "Whatsapp Charge",
      dataIndex: ["prices", "courier", "pickupWhatsapp"],
      render: (text) => (text ? Number(text).toFixed(2) : "-"),
    },
    {
      title: "Reschedule Whatsapp Charge",
      dataIndex: ["prices", "courier", "rescheduleWhatsapp"],
      render: (text) => (text ? Number(text).toFixed(2) : "-"),
    },
    {
      title: "Hide Cod Amount Charge",
      dataIndex: ["prices", "charges", "hideCodAmount"],
      render: (text) => (text ? Number(text).toFixed(2) : "-"),
    },
    {
      title: "Hide Logo Charge",
      dataIndex: ["prices", "charges", "hideLogo"],
      render: (text) => (text ? Number(text).toFixed(2) : "-"),
    },
    {
      title: "Hide Address Charge",
      dataIndex: ["prices", "charges", "hideAddress"],
      render: (text) => (text ? Number(text).toFixed(2) : "-"),
    },
    {
      title: "Insurance Charge",
      dataIndex: ["prices", "courier", "insurance"],
      render: (text) => (text ? Number(text).toFixed(2) : "-"),
    },
    {
      title: (
        <span>
          Original COD Charge{" "}
          <Tooltip title="Original COD Charge recorded during Checkout. This value will never change.">
            <InfoCircleOutlined />
          </Tooltip>
        </span>
      ),
      dataIndex: "snapshot",
      ellipsis: true,
      render: (snapshot) => {
        return snapshot?.prices?.courier?.cod
          ? Number(snapshot?.prices?.courier?.cod).toFixed(2)
          : "-";
      },
    },
    {
      title: "COD Charge",
      dataIndex: ["prices", "courier", "cod"],
      render: (text) => (text ? Number(text).toFixed(2) : "-"),
    },
    {
      title: (
        <span>
          Original NDR Charge{" "}
          <Tooltip title="Original Next-Day Remittance Charge recorded during Checkout. This value will never change.">
            <InfoCircleOutlined />
          </Tooltip>
        </span>
      ),
      dataIndex: "snapshot",
      ellipsis: true,
      render: (snapshot) => {
        return snapshot?.prices?.charges?.nextDayRemittance
          ? Number(snapshot?.prices?.charges?.nextDayRemittance).toFixed(2)
          : "-";
      },
    },
    {
      title: "NDR Charge",
      dataIndex: ["prices", "charges", "nextDayRemittance"],
      render: (text) => (text ? Number(text).toFixed(2) : "-"),
    },
    {
      title: (
        <span>
          Original Return Handling Fee Charge{" "}
          <Tooltip title=" Original Return Handling Fee Charge recorded during Checkout. This value will never change.">
            <InfoCircleOutlined />
          </Tooltip>
        </span>
      ),
      dataIndex: "snapshot",
      ellipsis: true,
      render: snapshot => {
        return snapshot?.prices?.courier?.returnFee ? Number(snapshot?.prices?.courier?.returnFee).toFixed(2) : "-"
      },
    },
    {
      title: "Return Handling Fee Charge",
      dataIndex: ["prices", "courier", "returnFee"],
      render: (text) => (text ? Number(text).toFixed(2) : "-"),
    },
    {
      title: "Remitted",
      dataIndex: "isRemittance",
      render: (text) => (text === true ? "Yes" : "-"),
    },
    {
      title: "Remitted At (YYYY-MM-DD)",
      dataIndex: "remittedAt",
      render: (text) => (text ? formatDateTime(text) : "-"),
    },
    {
      title: "Delivered",
      dataIndex: "completeDelivered",
      render: (text) => (text === true ? "Yes" : "-"),
    },
    {
      title: "Delivered At (YYYY-MM-DD)",
      dataIndex: "completeDeliveredAt",
      render: (text) => (text ? formatDateTime(text) : "-"),
    },
    {
      title: "Return Requested",
      dataIndex: "isReturnRequested",
      render: (text) => (text === true ? "Yes" : "-"),
    },
    {
      title: "Return Requested At (YYYY-MM-DD)",
      dataIndex: "returnRequestedAt",
      render: (text) => (text ? formatDateTime(text) : "-"),
    },
    {
      title: "COD Refunded",
      dataIndex: "isCodRefunded",
      render: (text) => (text === true ? "Yes" : "-"),
    },
    {
      title: "COD Refunded At (YYYY-MM-DD)",
      dataIndex: "codRefundedAt",
      render: (text) => (text ? formatDateTime(text) : "-"),
    },
    {
      title: "Return Handling Fee Refunded",
      dataIndex: "isReturnFeeRefunded",
      render: (text) => (text === true ? "Yes" : "-"),
    },
    {
      title: "Return Handling Fee Refunded At (YYYY-MM-DD)",
      dataIndex: "returnFeeRefundedAt",
      render: (text) => (text ? formatDateTime(text) : "-"),
    },
    {
      title: "Reference",
      dataIndex: "reference",
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Channel",
      dataIndex: "channel",
      render: (text) => (text ? text : "Untracked"),
    },
    {
      title: "Issue Invoice",
      dataIndex: "issueInvoice",
      render: (text) => (text ? "Yes" : "No"),
    },
    {
      title: "Courier Message",
      dataIndex: "courierMessage",
      align: "center",
      render: (text) =>
        text ? (
          <Button
            icon={<InfoCircleOutlined />}
            shape="circle"
            size="small"
            type="primary"
            onClick={() => showMessage(text)}
          ></Button>
        ) : (
          "-"
        ),
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, row) => (
        <Dropdown overlay={createActionMenu(row)}>
          <Button>
            Actions <DownOutlined />
          </Button>
        </Dropdown>
      ),
      fixed: "right",
    },
  ];

  const showMessage = (text) => {
    let courierResponse = JSON.stringify(JSON.parse(text), null, 2);
    setMessageData(courierResponse);
    setIsMessageVisible(true);
  };

  const markCancelledHandler = (order) => {
    const confirmationMessage = (
      <div>
        <p className="marginBottom-20">
          Current Status: <strong>{order.internalOrderStatus}</strong>
        </p>
        <p className="marginBottom-20">
          <strong>Please ensure you have:</strong>
          <ol>
            <li>Informed Courier on this Cancellation</li>
            <li>Processed the refund for this Order</li>
          </ol>
        </p>
        Else, cancel the Order through "Bulk Cancel Order" upload.
      </div>
    );

    Modal.confirm({
      content: confirmationMessage,
      title: `Confirm to manually mark ${order.consignNote} as Cancelled By User?`,
      width: 800,
      onOk() {
        axios
          .post(`/api/order/${order.orderId}/mark-cancelled`)
          .then((res) => {
            onMarkCancelled(order);
            message.info(
              `Order (${order.orderId}) has been marked as Cancelled By User.`
            );
          })
          .catch((error) => {
            message.error(error.response.data.message || error.message);
          });
      },
    });
  };

  const cancelOrderCod = async (order) => {
    setCancelCod(true);
    const messageContent = (
      <div>
        <p>Are you sure you want to cancel the COD for this order ?</p>
        Order Id : <b>{order.orderId}</b>
        <br />
        Tracking Number : <b>{order.consignNote}</b>
        <br />
        <br />
        <p className="marginBottom-20">
          <strong>Please ensure you have:</strong>
          <ol>
            <li>Informed Courier on this COD Cancellation</li>
          </ol>
        </p>
      </div>
    );

    Modal.confirm({
      content: messageContent,
      title: `Cancel COD ? `,
      width: 700,
      async onOk() {
        const body = {
          newCodValue: 0,
          trackingNumber: order.consignNote,
        };
        await axios
          .post(`/api/order/${order.orderId}/update-cod`, body)
          .then((res) => {
            message.info(`COD details for this order has been updated`);
          })
          .catch((error) => {
            message.error(error.message);
          });
      },
    });
    setCancelCod(false);
  };

  const editOrderCod = async (order) => {
    setCancelCod(true);
    const messageContent = (
      <div>
        <Form form={form} layout="vertical">
          <p>Are you sure you want to edit COD for this order?</p>
          <p>
            Order Id: <b>{order.orderId}</b>
            <br />
            Tracking Number: <b>{order.consignNote}</b>
          </p>
          <Form.Item label="COD Value" name="codValue">
            <InputNumber
              type="number"
              min={1}
              max={3000}
              defaultValue={order.cod}
            />
          </Form.Item>

          <p className="marginBottom-20">
            <strong>Please ensure you have:</strong>
            <ol>
              <li>Informed Courier on this COD value update</li>
            </ol>
          </p>
        </Form>
      </div>
    );

    Modal.confirm({
      content: messageContent,
      title: `Edit COD ? `,
      width: 700,
      async onOk() {
        const value = form.getFieldValue("codValue");
        const body = {
          newCodValue: value,
          trackingNumber: order.consignNote,
        };
        await axios
          .post(`/api/order/${order.orderId}/update-cod`, body)
          .then((res) => {
            message.info(`COD details for this order has been updated`);
          })
          .catch((error) => {
            message.error(error.message);
          });
      },
    });
    setCancelCod(false);
  };

  const viewPOD = async (order) => {
    const connote = order.consignNote;
    axios
      .get(`/api/pod/${connote}`, {
        responseType: "text",
      })
      .then((response) => {
        const imageUrl = response.data;
        setImgUrl(imageUrl);
        setIsPodVisible(true);
      })
      .catch(async (err) => {
        const textError = JSON.parse(await err.response.data.text());
        console.error(textError);
      });
  };

  const refundCodHandler = (order) => {
    const confirmationMessage = (
      <p>
        COD Charge: RM {order.prices.courier.cod}
        <br />
        Next Day Remittance Charge: RM{" "}
        {order.prices.charges.nextDayRemittance || 0}
        <br />
        <strong>Total</strong>: RM{" "}
        {order.prices.courier.cod + order.prices.charges.nextDayRemittance || 0}
      </p>
    );

    Modal.confirm({
      content: confirmationMessage,
      title: `Are you sure to refund COD charge for this Order?`,
      onOk() {
        axios
          .post(`/api/order/${order.orderId}/refund-cod`)
          .then((res) => {
            onCodRefunded();
            message.info(
              `COD Charge for Order (${order.orderId}) has been refunded.`
            );
          })
          .catch((error) => {
            message.error(error.response.data.message || error.message);
          });
      },
    });
  };

  const viewInvoice = async (id) => {
    try {
      const response = await axios.get(`/api/orders/${id}/preview-invoice`);
      window.open(response.data, "_blank");
    } catch (err) {
      notification.error({
        message: `Error when retrieve the invoice`,
        description: err.response?.data.message || err.message,
      });
    }
  };

  const viewChangesLog = async (id) => {
    axios
      .get(`/api/orders/${id}/changes-log`)
      .then((response) => {
        setLogVisible(true);
        const res = response.data.data;
        console.log(response.data);
        setLog(res);
      })
      .catch(async (err) => {
        const textError = JSON.parse(await err.response.data.text());
        console.error(textError);
      });
  };

  const syncOrderStatus = (order) => {
    const trackingNo = order.consignNote;
    const confirmationMessage = (
      <p>
        Tracking Number : {trackingNo}
        <br />
        Order Id : {order.orderId}
      </p>
    );

    Modal.confirm({
      content: confirmationMessage,
      title: `Are you sure to update the status for this order?`,
      onOk() {
        axios
          .post(`/api/order/sync-status`, { trackingNo })
          .then((res) => {
            message.info(`Order Status Updated`);
          })
          .catch((error) => {
            message.error(error.response.data.message || error.message);
          });
      },
    });
  };

  return (
    <div className="table">
      <Table
        rowKey="orderId"
        rowSelection={{ selectedRows, onChange: onSelectRows }}
        pagination={false}
        columns={columns}
        dataSource={orders}
        scroll={{ x: true }}
        loading={loading}
      />
      <Modal
        title="Message"
        visible={isMessageVisible}
        onOk={() => setIsMessageVisible(false)}
        onCancel={() => setIsMessageVisible(false)}
      >
        <pre>{messageData}</pre>
      </Modal>

      <Modal
        title="Change Log"
        visible={isLogVisible}
        onOk={() => setLogVisible(false)}
        onCancel={() => setLogVisible(false)}
      >
        <ol>
          {log.map((log, index) => (
            <li key={index}>
              <p>
                <strong>{formatDateTime(log.updatedAt)}</strong>
                <br />
                Cod value has been updated from{" "}
                <strong>RM{log.changes.old.cod}</strong> to{" "}
                <strong>RM{log.changes.attributes.cod}</strong> by{" "}
                <strong>{log.updateBy}</strong>
              </p>
            </li>
          ))}
        </ol>
      </Modal>

      <Modal
        title="View Invoice"
        visible={modalIsOpen}
        contentLabel="View Invoice Modal"
        onOk={() => setModalIsOpen(false)}
        onCancel={() => setModalIsOpen(false)}
        width="90vw"
        bodyStyle={{ height: "70vh", overflow: "auto" }}
        style={{
          margin: "auto",
        }}
      >
        {fileUrl &&
          (fileType.startsWith("image/") ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <img
                src={fileUrl}
                alt="Preview Invoice"
                style={{
                  width: "auto",
                  height: "auto",
                  maxWidth: "100%",
                  maxHeight: "100%",
                }}
              />
            </div>
          ) : fileType === "application/pdf" ? (
            <iframe
              src={fileUrl}
              title="Preview Invoice"
              height="100%"
              style={{
                maxWidth: "100%",
                width: "100%",
                minHeight: "50vh",
              }}
            />
          ) : (
            <p>Unsupported content type</p>
          ))}
      </Modal>

      <Modal
        title="Proof of Delivery"
        visible={isPodVisible}
        onOk={() => setIsPodVisible(false)}
        onCancel={() => setIsPodVisible(false)}
      >
        <div>
          <img
            src={imgUrl}
            alt="Preview"
            style={{ width: "50%", maxWwidth: "100%", maxHeight: "30%" }}
          />
        </div>
      </Modal>
    </div>
  );
};

export default OrdersList;
