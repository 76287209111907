import React, { useState } from 'react'
import axios from 'axios'
import moment from 'moment'
import { Button, Space, notification, Dropdown, Menu, Modal, message} from 'antd';
import { UndoOutlined, DownloadOutlined, LayoutOutlined } from "@ant-design/icons"
import { PERMISSIONS } from "../../constants/roles";
import { usePermissions } from "../App/PermissionContext"; 

const OrderButtons = ({selectedRowKeys, onRefunded, filters, orders}) => {
  const { permissions } = usePermissions();
  const [isDownloading, setIsDownloading] = useState(false)
  const [isRefunding, setIsRefunding] = useState(false)
  const [totalPage, setTotalPage] = useState()

  const downloadAllOrderCSV = async () => {
    setIsDownloading(true)
        const messageContent = <p>
          Are you sure you want to download {filters.pagination.totalSize} orders data ?<br/>
        </p>

        Modal.confirm({
          content: messageContent,
          title: `Download Order List ? `,
          async onOk() {
            await axios
              .get("api/allOrder/csv", { params: filters }).then(res => {
                message.info(`Please check your email after 5-20 minute`)     
              }).catch(error => {
                message.error(error.message)
              })
          },
        });
        setIsDownloading(false)
  }

  const downloadCSV = async () => {
    setIsDownloading(true)
        
    const messageContent = <p>
      Are you sure you want to download {filters.pagination.totalSize} orders data ?<br/>
    </p>

    Modal.confirm({
      content: messageContent,
      title: `Download Order List ? `,
      async onOk() {
        await axios
          .get("api/allOrder/csv", { params: filters }).then(res => {
            message.info(`Please check your email after 5-20 minute`)     
          }).catch(error => {
            message.error(error.message)
          })
      },
    });
    
    setIsDownloading(false)
  }

  const refundOrders = orderIds => {
    setIsRefunding(true)

    const allRefunds = Promise.all(
      orderIds.map(orderId => axios.patch("api/order/refund", { orderId }))
    );

    allRefunds
      .then(() => onRefunded())
      .catch(() => {
        console.log("Some refunds didn't work");
      });

    setIsRefunding(false)
  }

  const bulkDownload = async(e) => {
    setIsDownloading(true)

    const connoteType = e.key === "thermal" ? "Thermal" : ""
    const selectedOrders = orders.filter(order => selectedRowKeys.includes(order.orderId))
    const selectedServiceProviders = selectedOrders.map(order => order.serviceProvider)
      .filter((selected, index, all) => all.indexOf(selected) === index);

    const urlList = selectedOrders.map(order => {
      const file = process.env.REACT_APP_SERVER_URL + "/consign-pdf/" + order.consignNote + connoteType;
      return { url: file, name: order.consignNote };
    });

    const downloadPromises = selectedServiceProviders.map(async serviceProvider => {
      const date = moment().utcOffset("+0800").format("DD-MM-YYYY")

      return await axios
          .post(
              `/api/bulkdownload${serviceProvider}`, 
              urlList, 
              { responseType: "blob" }
          )
          .then((response) => {
              const blob = new Blob([response.data], { type: "application/pdf" });
              const link = document.createElement("a");
              link.href = URL.createObjectURL(blob);
              link.download = `${serviceProvider}_${date}.pdf`
              link.click();
              URL.revokeObjectURL(link.href)
          })
          .catch((error) => {
              notification.error({
                message: `Error when bulk downloading consignment notes for ${serviceProvider}`,
                description: error.response?.data.message || error.message
              })
          });
    })

    await Promise.all(downloadPromises)
    setIsDownloading(false)
  }

  const downloadMenu = (
    <Menu onClick={e => bulkDownload(e)}>
      <Menu.Item key="a4" icon={<LayoutOutlined />}>A4</Menu.Item>
      <Menu.Item key="thermal" icon={<LayoutOutlined />}>Thermal</Menu.Item>
    </Menu>
  );

  return (
    <div style={{ marginBottom: 16, float: "right" }}>
      <Space>
        <span color="blue">
        {permissions?.includes(PERMISSIONS.REFUND_ORDER) && onRefunded && (
            <Button
              onClick={() => refundOrders(selectedRowKeys)}
              loading={isRefunding}
              disabled={selectedRowKeys.length === 0}
            >
              <UndoOutlined style={{ fontSize: "20px" }} /> Refund{" "}
            </Button>
          )}
        </span>

        <span>
        {permissions?.includes(PERMISSIONS.BULK_DOWNLOAD_CONNOTE) && (
            <Dropdown
              overlay={downloadMenu}
              disabled={isDownloading || selectedRowKeys.length === 0}
            >
              <Button>
                <Space>
                  {isDownloading ? "Downloading" : "Connote"}
                  <DownloadOutlined style={{ fontSize: "20px" }} />
                </Space>
              </Button>
            </Dropdown>
          )}
        </span>

        <span>
          {permissions?.includes(PERMISSIONS.EXPORT_LIST) &&
            (filters.pagination ? (
              <Button onClick={downloadAllOrderCSV} loading={isDownloading}>
                CSV <DownloadOutlined />
              </Button>
            ) : (
              <Button onClick={downloadCSV} loading={isDownloading}>
                CSV <DownloadOutlined />
              </Button>
            ))}
        </span>
      </Space>
    </div>
  )
}

export default OrderButtons